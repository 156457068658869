import { create } from "zustand"

interface MoodStore {
  currentMood: MoodItem | null
  setCurrentMood: (mood: MoodItem) => void
}

const useMoodStore = create<MoodStore>((set) => ({
  currentMood: null,
  setCurrentMood: (mood) => set({ currentMood: mood }),
}))

export default useMoodStore
