import { useShallow } from "zustand/react/shallow"

import Card from "@components/Cards/Card.tsx"

import BaseInput from "../../../components/Forms/BaseInput.tsx"
import GenericSettingsCardDescription from "../Generic/GenericSettingsCardDescription.tsx"
import SettingsSaveButton from "../Generic/SettingsSaveButton.tsx"
import useCoachProfileStore from "../stores/CoachProfileStore.ts"

const CalendarSettingsCard = () => {
  const calendar_url = useCoachProfileStore(useShallow((state) => state.profile?.calendar_url))
  const setter = useCoachProfileStore((state) => state.setter)
  const hasChanged = useCoachProfileStore((state) => state.hasChanged)
  const saveProfile = useCoachProfileStore((state) => state.handleSave)

  return (
    <Card>
      <h1 className={"text-2xl pb-8"}>Calendar Settings</h1>
      <GenericSettingsCardDescription>
        Your calendar is used to schedule appointments with your clients. You can connect your calendar to allow clients
        to sign up for appointments through the app.
      </GenericSettingsCardDescription>
      <BaseInput
        label={"Google Calendar Booking Link"}
        id={"google_calendar_link"}
        value={calendar_url}
        onChange={(event) => setter("calendar_url", event.target.value)}
        placeholder={
          "e.g. https://calendar.google.com/calendar/appointments/schedules/AcZssZ3MDsf6Oeu4K7cuTjrzc-M4W29J9xODSBAxdXuy1JDsVhC3OlOIyFOV5q581Sbq8tRN1nv6bCxl?gv=true"
        }
      />
      <SettingsSaveButton onClick={saveProfile} disabled={!hasChanged} />
    </Card>
  )
}

export default CalendarSettingsCard
