import { create } from "zustand"

interface HabitsSummaryStore {
  currentHabitsResponse: HabitsSummaryResponse | null
  setCurrentHabitsResponse: (response: HabitsSummaryResponse) => void
}

const useHabitsSummaryStore = create<HabitsSummaryStore>((set) => ({
  currentHabitsResponse: null,
  setCurrentHabitsResponse: (currentHabitsResponse) => set({ currentHabitsResponse }),
}))

export default useHabitsSummaryStore
