import { create } from "zustand"

interface SleepSummaryStore {
  currentSleepResponse: SleepSummaryResponse | null
  setCurrentSleepResponse: (response: SleepSummaryResponse) => void
}

const useSleepSummaryStore = create<SleepSummaryStore>((set) => ({
  currentSleepResponse: null,
  setCurrentSleepResponse: (currentSleepResponse) => set({ currentSleepResponse }),
}))

export default useSleepSummaryStore
