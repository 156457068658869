import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"

const SleepSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <SectionContainer title="[] - Survey" description="All stats latest available score within the last 90 days">
      <div className="flex gap-3">
        <Card additionalClasses={cardStyle}>🚧 work in progress 🚧</Card>
      </div>
    </SectionContainer>
  )
}

export default SleepSection
