import useMoodStore from "../stores/MoodStore"

const MoodColorGraph = () => {
  const currentMood = useMoodStore((state) => state.currentMood)
  const mood = currentMood?.mood_name || "Neutral"
  const moodKey = mood.toLowerCase()
  const intensity = currentMood?.intensity || 0
  const intensityToDisplay = Math.round(intensity * 10)

  return (
    <div className="relative flex flex-col justify-start h-full w-full rounded-lg overflow-hidden">
      <div className="relative z-10 text-xs font-light p-4 w-full text-center mt-3">Mood</div>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-row justify-between">
          <div className="relative z-10 px-8 text-4xl font-light">{mood}</div>
          <div className="relative z-10 px-8 text-4xl font-light">{intensityToDisplay}</div>
        </div>
      </div>
      <div
        className={`absolute top-0 left-0 h-full w-full bg-${moodKey}-200 animate-[pulse_3s_infinite] rounded-full blur-2xl filter opacity-60`}
      ></div>
      <div
        className={`absolute top-0 left-0 h-full w-full bg-${moodKey}-200 animate-[pulse_5s_infinite] rounded-xl blur-xl filter opacity-20`}
      ></div>
    </div>
  )
}

export default MoodColorGraph
