import { set as lodashSet } from "lodash"
import { create } from "zustand"

import api from "@utilities/api.ts"

interface AddAssignmentStoreState {
  assignments: Record<string, AdminAssignmentItem>
  getAssignmentsList: () => AdminAssignmentItem[]
  availableAssignments: AdminAssignmentItem[]
}

interface AddAssignmentStoreFunctions {
  transformAssignments: (assignments: AdminAssignmentItem[]) => void
  setAssignments: (assignments: Record<string, AdminAssignmentItem>) => void
  setAvailableAssignments: (assignments: AdminAssignmentItem[]) => void
  updateAssignment: (rowId: string, keyPath: string, value: string | string[] | null) => void
  submitAssignmentsToAPI: (user_id: string) => Promise<{ message: string }>
}

type AddAssignmentStore = AddAssignmentStoreState & AddAssignmentStoreFunctions

const useAddAssignmentStore = create<AddAssignmentStore>((set, get) => ({
  assignments: {},
  getAssignmentsList: () => {
    const assignments = get().assignments // Use get() to access assignments
    if (!assignments) return []
    return Object.keys(assignments).map((id) => assignments[id])
  },
  availableAssignments: [],
  transformAssignments: (assignments: AdminAssignmentItem[]) => {
    const assignmentsObject = assignments.reduce<Record<string, AdminAssignmentItem>>((acc, assignment) => {
      acc[assignment.template_feed_item_id] = assignment
      return acc
    }, {}) // Ensure initial value is an empty object
    set(() => ({ assignments: assignmentsObject }))
  },
  setAssignments: (assignments: Record<string, AdminAssignmentItem>) => {
    set(() => ({ assignments: { ...assignments } }))
  },
  updateAssignment: (rowId: string, keyPath: string, value) => {
    const assignment = get().assignments[rowId]
    const newAssignmentValue = lodashSet({ ...assignment }, keyPath, value)
    set(() => ({ assignments: { ...get().assignments, [rowId]: newAssignmentValue } }))
  },
  setAvailableAssignments: (assignments: AdminAssignmentItem[]) => set(() => ({ availableAssignments: assignments })),
  submitAssignmentsToAPI: async (user_id: string) => {
    try {
      const assignments = get().getAssignmentsList()
      const feedItems = assignments.map((assignment) => assignment.template_feed_item)

      const { data, status } = await api.post<{ message: string }>(`/admin/users/${user_id}/assignments`, { feedItems })

      if (status === 200) return data
      throw new Error(data.message)
    } catch (err) {
      return { message: "Failed to add assignments" }
    }
  },
}))

export default useAddAssignmentStore
