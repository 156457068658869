import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import Card from "@components/Cards/Card.tsx"

import api from "@utilities/api.ts"
import { useInvalidateQuery } from "@utilities/invalidateQueries.ts"

import useFeedStore from "../stores/FeedStore"

const FeedProvider = ({ children }: { children: ReactNode }) => {
  const invalidateFeedData = useInvalidateQuery(["calendarData"], 3000)

  const setCurrentFeedResponse = useFeedStore((state) => state.setCurrentFeedResponse)

  const { date } = useParams()
  const currentActiveDate = date || new Date().toISOString().split("T")[0]

  const {
    data: FeedAPIResponse,
    isSuccess: isSuccessFeed,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["feedData", currentActiveDate],
    queryFn: async () => {
      const endpoint = "/feed/self/" + currentActiveDate + "?debug=True"
      const response = await api.get<FeedAPIResponse>(endpoint)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching Feed data failed")
    },
  })

  useEffect(() => {
    if (isSuccessFeed) {
      // TODO(05/14/24): This is a temporary fix till the API is updated
      setCurrentFeedResponse(FeedAPIResponse.data)
    }
  }, [FeedAPIResponse, isSuccessFeed, setCurrentFeedResponse, currentActiveDate])

  useEffect(() => {
    void invalidateFeedData()
  }, [currentActiveDate, invalidateFeedData])
  if (isLoading)
    return (
      <div className="mt-6">
        <Card backgroundColor="neutral-50" additionalClasses="shadow-none">
          <LoadingSpinner size="2x" />
        </Card>
      </div>
    )
  if (isError)
    return (
      <div className="mt-6">
        <Card backgroundColor="neutral-50" additionalClasses="shadow-none">
          Failed to load feed
        </Card>
      </div>
    )
  return children
}

export default FeedProvider
