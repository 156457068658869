import MoodChartSection from "./MoodChartSection"
import MoodMapSection from "./MoodMapSection"

const MoodPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Mood</h3>
      </div>

      <MoodMapSection />
      <MoodChartSection />
    </div>
  )
}

export default MoodPage
