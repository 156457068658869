import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import ActivityGraphD3 from "./D3Files/ActivityGraphD3.js"

interface ActivityGraphProps {
  graphData?: ThumbnailGraphData[]
  name?: string
  color?: string
}

const ActivityGraph = ({ graphData = [], name = "Daily Graph", color = "#5E89FF" }: ActivityGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const currentGraphData = graphData
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const hasValidData = graphData?.length > 0

  useEffect(() => {
    if (isValidSize && hasValidData) {
      new ActivityGraphD3(chartArea.current, containerSize.width, containerSize.height, currentGraphData, color)
    }
  }, [containerSize, graphData, currentGraphData, isValidSize, hasValidData, color])

  return (
    <div className="w-full h-auto overflow-hidden">
      <div className="text-xl">{name}</div>
      <div className="w-full h-auto overflow-hidden" ref={containerRef}>
        <div className="chart-area flex-1 w-full h-48 overflow-hidden" ref={chartArea}></div>
      </div>
    </div>
  )
}

export default ActivityGraph
