import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import api from "@utilities/api.ts"
import { useInvalidateQuery } from "@utilities/invalidateQueries.ts"

import ActivityGraphCardLoading from "../ActivityGraphCardLoading.tsx"
import useStepSummaryStore from "../store/StepSummaryStore.ts"

const StepsSummaryProvider = ({ children }: { children: ReactNode }) => {
  const invalidateInsightData = useInvalidateQuery(["calendarData"], 3000)
  const setCurrentStepsData = useStepSummaryStore((state) => state.setCurrentStepsResponse)
  const { clientId } = useParams()

  const {
    data: stepsDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["stepsData", clientId],
    queryFn: async () => {
      const endpoint = `insight/summary/steps/${clientId}`
      const { data, status } = await api.get<StepsSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Steps data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && stepsDataResponse) {
      setCurrentStepsData(stepsDataResponse)
    }
  }, [stepsDataResponse, isSuccess, setCurrentStepsData])

  useEffect(() => {
    void invalidateInsightData()
  }, [clientId, invalidateInsightData])

  if (isLoading) return <ActivityGraphCardLoading label="Daily Steps" />
  if (isError) return <ActivityGraphCardLoading label="Daily Steps" />

  return children
}

export default StepsSummaryProvider
