import useSummaryHeaderStore from "../summary/store/SummaryHeaderStore"
import ProfileSummary from "./ProfileSummary"

const InsightReportHeader: React.FC = () => {
  const { currentSummaryHeaderResponse } = useSummaryHeaderStore()

  if (!currentSummaryHeaderResponse) {
    return null // Render nothing or a loading state if the data is not yet available
  }

  const { profile_data, hardware_versions } = currentSummaryHeaderResponse
  const firstName = profile_data?.first_name || ""
  const lastName = profile_data?.last_name || ""
  const age = profile_data?.age?.toString() || "N/A"
  const dob = profile_data?.birthdate || "N/A"
  const gender = profile_data?.sex || "N/A"

  const rowMarginBottom = "mb-3"

  return (
    <div className={`w-full ${rowMarginBottom} justify-center flex`}>
      <ProfileSummary
        firstName={firstName}
        lastName={lastName}
        hardwareVersion={hardware_versions}
        age={age}
        dob={dob}
        gender={gender}
      />
    </div>
  )
}

export default InsightReportHeader
