import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import HabitsSummaryContent from "./HabitsSummaryContent"
import HabitsSummaryProvider from "./provider/HabitsSummaryProvider"

const HabitsSummarySection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <SectionContainer title="Habits" description="All stats represent median over last 7 days">
      <Card additionalClasses={cardStyle}>
        <HabitsSummaryProvider>
          <HabitsSummaryContent />
        </HabitsSummaryProvider>
      </Card>
    </SectionContainer>
  )
}

export default HabitsSummarySection
