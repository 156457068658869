import HabitScaleDots from "../atom/HabitScaleDots"

interface HabitsItemRowProps {
  dayOfWeek: string
  date?: string
  habitScaleMap: {
    meds: number | null
    alcohol: number | null
    cannabis: number | null
    caffeine: number | null
    screen: number | null
    junkFood: number | null
  }
}

const HabitsItemRow: React.FC<HabitsItemRowProps> = ({ dayOfWeek, date, habitScaleMap }) => {
  // date reformat 2024-09-02 to 09/02
  const formatDate = (date: string | undefined) => {
    if (!date) return ""
    const dateArr = date.split("-")
    return `${dateArr[1]}/${dateArr[2]}`
  }

  return (
    <div className="relative flex flex-row justify-between items-center w-full not-last:border-b border-neutral-100 py-3 hover:bg-neutral-50">
      {/* Day of the week */}
      <div className="min-w-[100px] text-xs text-neutral-300">
        {formatDate(date)} {dayOfWeek}
      </div>

      {/* Habit scale dots */}
      <div className="min-w-[60px] text-xs text-neutral-300">
        <HabitScaleDots scale={habitScaleMap.meds} />
      </div>
      <div className="min-w-[60px] text-xs text-neutral-300">
        <HabitScaleDots scale={habitScaleMap.alcohol} />
      </div>
      <div className="min-w-[60px] text-xs text-neutral-300">
        <HabitScaleDots scale={habitScaleMap.cannabis} />
      </div>
      <div className="min-w-[60px] text-xs text-neutral-300">
        <HabitScaleDots scale={habitScaleMap.caffeine} />
      </div>
      <div className="min-w-[60px] text-xs text-neutral-300">
        <HabitScaleDots scale={habitScaleMap.screen} />
      </div>
      <div className="min-w-[60px] text-xs text-neutral-300">
        <HabitScaleDots scale={habitScaleMap.junkFood} />
      </div>
    </div>
  )
}

export default HabitsItemRow
