import { Row } from "@tanstack/react-table"
import { MoreHorizontal } from "lucide-react"

import { Button } from "@/shadcn-components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shadcn-components/ui/dropdown-menu.tsx"

import useAddAssignmentStore from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsStore.ts"

interface Props {
  row: Row<AdminAssignmentItem>
}

const ModifyAssignmentActionMenu = ({ row }: Props) => {
  const assignments = useAddAssignmentStore((state) => state.assignments)
  const setAssignments = useAddAssignmentStore((state) => state.setAssignments)
  const handleRemove = () => {
    const newAssignments = assignments

    delete newAssignments[row.original.template_feed_item_id]

    setAssignments(newAssignments)
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant={"ghost"} className={"h-8 w-8 p-0"}>
          <span className={"sr-only"}>Open menu</span>
          <MoreHorizontal className={"h-4 w-4"} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align={"end"}>
        <DropdownMenuItem onClick={handleRemove}>Remove</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ModifyAssignmentActionMenu
