import { Button } from "@/shadcn-components/ui/button.tsx"
import { DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/shadcn-components/ui/dialog.tsx"

import ModifyAssignmentsList from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/ModifyAssignmentsList.tsx"

interface Props {
  setPage: (page: number) => void
}

const ModifyAssignments = ({ setPage }: Props) => {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Modify Assignments</DialogTitle>
        <DialogDescription>
          You can optionally update any of the assignments you're about to make for the patient.
        </DialogDescription>
        <ModifyAssignmentsList />
        <DialogFooter>
          <Button onClick={() => setPage(0)}>Back</Button>
          <Button onClick={() => setPage(2)}>Next</Button>
        </DialogFooter>
      </DialogHeader>
    </>
  )
}

export default ModifyAssignments
