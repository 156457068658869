import LocationSection from "./LocationSection"
import SleepAndStepsSection from "./SleepAndStepsSection"

const ActivityPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Activity</h3>
      </div>

      <SleepAndStepsSection />
      <LocationSection />
    </div>
  )
}

export default ActivityPage
