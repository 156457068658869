import { create } from "zustand"

interface ManageCliniciansStoreState {
  clinicians: AdminClinicianItem[]
}

interface ManageCliniciansStoreFunctions {
  setClinicians: (clinicians: AdminClinicianItem[]) => void
}

type ManageCliniciansStore = ManageCliniciansStoreState & ManageCliniciansStoreFunctions

const useManageCliniciansStore = create<ManageCliniciansStore>((set) => ({
  clinicians: [],
  setClinicians: (clinicians: AdminClinicianItem[]) => set(() => ({ clinicians })),
}))

export default useManageCliniciansStore
