import { create } from "zustand"

interface CalendarStore {
  currentCalendarResponse: CalendarItem[]
  setCurrentCalendarResponse: (response: CalendarItem[]) => void
}

const useCalendarStore = create<CalendarStore>((set) => ({
  currentCalendarResponse: [],
  setCurrentCalendarResponse: (currentCalendarResponse) => set({ currentCalendarResponse }),
}))

export default useCalendarStore
