import { useState } from "react"

import Card from "@/components/Cards/Card.tsx"
import Modal from "@/components/Modals/Modal.tsx"

interface ProfileSummaryProps {
  firstName?: string
  lastName?: string
  age?: string
  dob?: string
  gender?: string
  emergencyContact?: string
  hardwareVersion?: string[]
}

const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  firstName = "...",
  lastName = "...",
  hardwareVersion = [],
  age = "...",
  dob = "...",
  gender = "...",
  emergencyContact = "...",
}) => {
  const cardStyle = "relative border border-neutral-200 w-full shadow-none p-0 overflow-hidden max-w-5xl"

  const [isEmergencyModalOpen, setEmergencyModalOpen] = useState(false)
  const [isDeviceModalOpen, setDeviceModalOpen] = useState(false)

  // Default hardware version text if empty
  if (hardwareVersion.length === 0) {
    hardwareVersion = ["no device info available"]
  }

  const handleOpenEmergencyModal = () => setEmergencyModalOpen(true)
  const handleCloseEmergencyModal = () => setEmergencyModalOpen(false)

  const handleOpenDeviceModal = () => setDeviceModalOpen(true)
  const handleCloseDeviceModal = () => setDeviceModalOpen(false)

  return (
    <Card additionalClasses={cardStyle} padding="p-6">
      {/* Content Overlay */}
      <div className="relative z-10 p-2">
        <div className="text-3xl mb-6">
          {firstName} {lastName}
        </div>
        <div className="flex flex-col w-full items-start justify-between">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row space-x-2 mt-2">
              {/* Gender */}
              <div className="relative group">
                <div className="border rounded-lg px-2 py-1 mr-2 flex flex-row min-w-12">{gender}</div>
                <span className="absolute bottom-full text-xs text-neutral-300">Gender</span>
              </div>

              {/* Age */}
              <div className="relative group">
                <div className="border rounded-lg px-2 py-1 mr-2 min-w-12">{age}</div>
                <span className="absolute bottom-full text-xs text-neutral-300">Age</span>
              </div>

              {/* DOB */}
              <div className="relative group">
                <div className="border rounded-lg px-2 py-1 mr-2 min-w-12">{dob}</div>
                <span className="absolute bottom-full transform text-xs text-neutral-300">DOB</span>
              </div>
            </div>

            {/* Emergency Contact and Device Info Buttons */}
            <div className="text-sm text-neutral-300 flex flex-col items-end ml-auto">
              <button onClick={handleOpenEmergencyModal} className="underline text-neutral-300">
                Emergency Contact
              </button>
              <button onClick={handleOpenDeviceModal} className="underline text-neutral-300">
                Device Info
              </button>
            </div>
          </div>
        </div>

        {/* Emergency Contact Modal */}
        <Modal isOpen={isEmergencyModalOpen} size="lg" title="Emergency Contact" onClose={handleCloseEmergencyModal}>
          <div className="p-4">
            <p>{emergencyContact}</p>
          </div>
        </Modal>

        {/* Device Info Modal */}
        <Modal isOpen={isDeviceModalOpen} size="lg" title="Device Info" onClose={handleCloseDeviceModal}>
          <div className="p-4">
            {hardwareVersion.map((version, index) => (
              <div key={index}>{version}</div>
            ))}
          </div>
        </Modal>
      </div>
    </Card>
  )
}

export default ProfileSummary
