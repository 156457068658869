import { faChevronCircleDown, faChevronCircleUp, faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface TrendIconProps {
  trend: "up" | "down" | "none"
  additionalClasses?: string
  reverseColor?: boolean
}

const getTrendIcon = (trend: string) => {
  if (trend === "up") return faChevronCircleUp
  if (trend === "down") return faChevronCircleDown
  return faMinusCircle
}

const getTrendColor = (trend: string, reverseColor: boolean) => {
  if (trend === "up") return reverseColor ? "text-red-500" : "text-green-500"
  if (trend === "down") return reverseColor ? "text-green-500" : "text-red-500"
  return "text-yellow-400"
}

const TrendIcon: React.FC<TrendIconProps> = ({ trend, additionalClasses, reverseColor = false }) => {
  const trendIcon = getTrendIcon(trend)
  const trendColor = getTrendColor(trend, reverseColor)

  return (
    <span className={`${trendColor} text-sm ${additionalClasses}`}>
      <FontAwesomeIcon icon={trendIcon} />
    </span>
  )
}

export default TrendIcon
