import { DataTable } from "@components/Tables/DataTable.tsx"
import useAddAssignmentStore from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsStore.ts"
import { ModifyAssignmentsListColumns } from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/ModifyAssignmentsListColumns.tsx"

const ModifyAssignmentsList = () => {
  const getAssignmentsList = useAddAssignmentStore((state) => state.getAssignmentsList)

  // subscribe to the assignments store to detect changes since we're using an K/V pair under the hood for performance reasons
  useAddAssignmentStore((state) => state.assignments)
  return <DataTable columns={ModifyAssignmentsListColumns} data={getAssignmentsList()} />
}

export default ModifyAssignmentsList
