import { Dispatch, SetStateAction } from "react"

import { DataTable } from "@components/Tables/DataTable.tsx"
import { ManageCliniciansListColumns } from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ManageCliniciansListColumns.tsx"
import useManageCliniciansStore from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ManageCliniciansStore.ts"

const ManageCliniciansList = ({
  setRowSelection,
}: {
  setRowSelection: Dispatch<SetStateAction<AdminClinicianItem[]>>
}) => {
  const clinicians = useManageCliniciansStore((state) => state.clinicians)
  return <DataTable columns={ManageCliniciansListColumns} data={clinicians} setSelectedRows={setRowSelection} />
}

export default ManageCliniciansList
