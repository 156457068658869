import Card from "@/components/Cards/Card.tsx"

import TrendIcon from "../atom/TrendIcon"
import ThumbnailGraph from "../graph/ThumbnailGraph"

interface ActivitySummaryGraphCardProps {
  label: string
  value: string | number | undefined
  unit: string
  trend?: "up" | "down" | "none"
  chartData?: ThumbnailGraphData[]
  color?: string
}
const ActivitySummaryGraphCard: React.FC<ActivitySummaryGraphCardProps> = ({
  label,
  value,
  unit,
  trend = "none",
  chartData,
  color = "#5E89FF",
}) => {
  const formattedValue = typeof value === "number" && value > 1000 ? `${(value / 1000).toFixed(1)}k` : value
  const valueToDisplay = formattedValue + unit
  return (
    <Card additionalClasses="border w-full shadow-none" padding="p-2">
      <div className="w-full flex flex-col items-center mt-4 mb-2">
        <div className="text-sm text-neutral-300 mb-2">{label}</div>
        <div className="flex">
          <TrendIcon trend={trend} additionalClasses="mt-2 mr-2" />
          <span className="text-3xl">{valueToDisplay} </span>
        </div>
        <ThumbnailGraph graphData={chartData} color={color} />
      </div>
    </Card>
  )
}

export default ActivitySummaryGraphCard
