import { create } from "zustand"

interface ManageUserStore {
  users: ListUser[]
  setUsers: (users: ListUser[]) => void
}

const useManageUserStore = create<ManageUserStore>((set) => ({
  users: [],
  setUsers: (users: ListUser[]) => set({ users }),
}))

export default useManageUserStore
