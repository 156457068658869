import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"

import TooltipTableHeader from "@/shadcn-components/ui/table-extensions.tsx"

import EditableCell from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/EditableCell.tsx"
import ModifyAssignmentActionMenu from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/ModifyAssignmentActionMenu.tsx"

export const ModifyAssignmentsListColumns: ColumnDef<AdminAssignmentItem>[] = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: () => (
      <TooltipTableHeader
        label={"Frequency"}
        title={"Frequency"}
        description={
          <>
            <p>Frequency lets you control how often an event repeats.</p>
            <br />
            <p>
              Hint: if you want a feed item to be repeated every week day, select "WEEKLY" and then check all of the
              week days.
            </p>
          </>
        }
      />
    ),
    accessorKey: "template_feed_item.freq",
    cell: ({ row }) => (
      <EditableCell
        row={row}
        id={"template_feed_item.freq"}
        type="select"
        options={[
          { value: null, label: "None" },
          { value: "DAILY", label: "Daily" },
          { value: "WEEKLY", label: "Weekly" },
          { value: "MONTHLY", label: "Monthly" },
          { value: "YEARLY", label: "Yearly" },
        ]}
      />
    ),
  },
  {
    header: "Days of Week",
    accessorKey: "template_feed_item.by_day",
    cell: ({ row }) => (
      <EditableCell
        row={row}
        id={"template_feed_item.by_day"}
        type="checkbox_group"
        className={"grid grid-cols-2 gap-x-2"}
        options={[
          { value: "MO", label: "MO" },
          { value: "TU", label: "TU" },
          { value: "WE", label: "WE" },
          { value: "TH", label: "TH" },
          { value: "FR", label: "FR" },
          { value: "SA", label: "SA" },
          { value: "SU", label: "SU" },
        ]}
      />
    ),
  },
  {
    header: () => (
      <TooltipTableHeader
        label={"Interval"}
        title={"Interval"}
        description={
          <>
            <p>The interval between each frequency iteration.</p>
            <br />
            <p>
              For example, if you select WEEKLY and an interval of 2, this feed item will show up every 2 weeks.
              Similarly, if you select MONTHLY and select an interval of 3, it will show up once per quarter (every 3
              months).
            </p>
          </>
        }
      />
    ),
    accessorKey: "template_feed_item.interval",
    cell: ({ row }) => (
      <EditableCell
        className={"w-[80px]"}
        row={row}
        id={"template_feed_item.interval"}
        type="input"
        inputType={"number"}
        placeholder={"--"}
      />
    ),
  },
  {
    accessorKey: "template_feed_item.date_start",
    cell: ({ row }) => <EditableCell row={row} id={"template_feed_item.date_start"} type="date" />,
    header: () => (
      <TooltipTableHeader
        label={"Start Date"}
        title={"Start Date"}
        description={
          <>
            <p>Start date will let you define when a feed item first shows up.</p>
          </>
        }
      />
    ),
  },
  {
    accessorKey: "template_feed_item.date_end",
    cell: ({ row }) => {
      const { date_end } = row.original.template_feed_item
      try {
        if (date_end) return format(row.original.template_feed_item.date_end, "P")
      } catch {
        return "Same as start"
      }

      return "Same as start"
    },
    header: () => (
      <TooltipTableHeader
        label={"End Date"}
        title={"End Date"}
        description={
          <>
            <p>
              End dates define how long a single instance of a feed item is good for. For example, if you have a survey
              that should be answered only once per week, you should set the end date to be the final day of the week
              (e.g. Sunday).
            </p>
            <br />
            <p>
              Hint: it is a mistake to combine recurrence rules (e.g. frequency, days of week, interval) with very long
              end dates.
            </p>
          </>
        }
      />
    ),
  },
  {
    id: "actions",
    cell: ({ row }) => <ModifyAssignmentActionMenu row={row} />,
  },
]
