import { type ClassValue, clsx } from "clsx"
import { useState } from "react"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// useDialog Hook
// useful for creating dialogs inside a dropdown menu (without having to define the dialog inline)
export function useDialog(): UseDialogProps {
  const [isOpen, setIsOpen] = useState(false)

  const trigger = () => setIsOpen(true)

  return {
    props: {
      open: isOpen,
      onOpenChange: setIsOpen,
    },
    trigger: trigger,
    dismiss: () => setIsOpen(false),
  }
}
