import { Link } from "react-router-dom"

import BasicProgressBar from "../../../../components/ProgressBars/BasicProgressBar.tsx"
import AssignmentAvailabilityDates from "../../../Assignment/AssignmentAvailabilityDates.tsx"

const ClientAssignmentCard = ({
  date_start,
  date_end,
  name,
  feed_item_interaction_id,
  completion,
}: ClientAssignment) => {
  console.log(date_start, date_end, name, feed_item_interaction_id, completion)

  return (
    <div className={"not-last:mb-6 border hover:shadow-xl p-4 rounded-lg duration-300 ease-in-out"}>
      {name === "Game" ? (
        <div>
          <div className={"text-xl font-bold"}>{name}</div>
          <AssignmentAvailabilityDates available_from={date_start} available_to={date_end} />
          <div className={"my-4"}>
            <BasicProgressBar fgColor={"black"} percent={Math.round(completion * 100)} />
          </div>
        </div>
      ) : (
        <Link to={`/pr/responses/${feed_item_interaction_id}`}>
          <div className={"text-xl font-bold"}>{name}</div>
          <AssignmentAvailabilityDates available_from={date_start} available_to={date_end} />
          <div className={"my-4"}>
            <BasicProgressBar fgColor={"black"} percent={Math.round(completion * 100)} />
          </div>
        </Link>
      )}
    </div>
  )
}

export default ClientAssignmentCard
