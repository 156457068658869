import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"
import { useInvalidateQuery } from "@utilities/invalidateQueries.ts"

import useHabitsSummaryStore from "../store/HabitsSummaryStore.ts"

const HabitsSummaryProvider = ({ children }: { children: ReactNode }) => {
  const invalidateInsightData = useInvalidateQuery(["calendarData"], 3000)
  const setCurrentHabitsResponse = useHabitsSummaryStore((state) => state.setCurrentHabitsResponse)
  const { clientId } = useParams()

  const {
    data: habitsDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["habitsData", clientId],
    queryFn: async () => {
      const endpoint = `insight/summary/habits/${clientId}`
      const { data, status } = await api.get<HabitsSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Habits data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && habitsDataResponse) {
      setCurrentHabitsResponse(habitsDataResponse)
    }
  }, [habitsDataResponse, isSuccess, setCurrentHabitsResponse])

  useEffect(() => {
    void invalidateInsightData()
  }, [clientId, invalidateInsightData])

  if (isLoading) return <LoadingSpinner size="2x" />

  if (isError) return <div className="mt-6">Failed to load daily habits</div>

  return children
}

export default HabitsSummaryProvider
