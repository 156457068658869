import { create } from "zustand"

interface SummaryHeaderStore {
  currentSummaryHeaderResponse: SummaryHeaderResponse | null
  setCurrentSummaryHeaderResponse: (response: SummaryHeaderResponse) => void
}

const useSummaryHeaderStore = create<SummaryHeaderStore>((set) => ({
  currentSummaryHeaderResponse: null,
  setCurrentSummaryHeaderResponse: (currentSummaryHeaderResponse) => set({ currentSummaryHeaderResponse }),
}))

export default useSummaryHeaderStore
