import { useOrganization } from "@clerk/clerk-react"
import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useSurveyStore from "@features/Surveys/stores/surveyStore.ts"

import api from "@utilities/api.ts"

const SurveyProvider = ({ children }: { children: ReactNode }) => {
  const setSurveys = useSurveyStore((state) => state.setSurveys)
  const setSurveySets = useSurveyStore((state) => state.setSurveySets)
  const { organization } = useOrganization()

  const {
    data: surveys,
    isPending: surveyIsPending,
    isError: surveyIsError,
  } = useQuery({
    queryKey: ["Surveys"],
    queryFn: async () => {
      const response = await api.get<Survey[]>("/surveys")
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  const {
    data: surveySets,
    isPending: surveySetIsPending,
    isError: surveySetIsError,
  } = useQuery({
    queryKey: ["SurveySets", organization?.slug],
    retry: false,
    queryFn: async () => {
      if (!organization?.slug) throw new Error("Current user does not belong to an organization")

      const response = await api.get<TemplateFeedItem[]>(`/template_feed_items/${organization?.slug}`)
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (surveySets) {
      setSurveySets(surveySets)
    }
  }, [setSurveySets, surveySets])

  useEffect(() => {
    if (surveys) {
      setSurveys(surveys)
    }
  }, [setSurveys, surveys])

  if (surveyIsPending || surveySetIsPending) return <LoadingSpinner fullScreen />
  if (surveyIsError || surveySetIsError) return
  return children
}
export default SurveyProvider
