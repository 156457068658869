import { Button } from "@/shadcn-components/ui/button.tsx"
import { DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/shadcn-components/ui/dialog.tsx"

import AddAssignmentsList from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsList.tsx"
import useAddAssignmentStore from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsStore.ts"

interface Props {
  setPage: (page: number) => void
}

const SelectAssignments = ({ setPage }: Props) => {
  const assignments = useAddAssignmentStore((state) => state.assignments)
  const selectedAssignmentCount = Object.keys(assignments).length
  return (
    <>
      <DialogHeader>
        <DialogTitle>Add Assignments</DialogTitle>
        <DialogDescription>
          Select at least 1 item that you'd like to assign to a user. On the next page, you'll be able to specify when
          an item shows up in that user's feed.
        </DialogDescription>
        <AddAssignmentsList />
        {selectedAssignmentCount > 0 && (
          <div className={"text-sm text-gray-500 mt-2"}>{selectedAssignmentCount} assignments selected</div>
        )}
      </DialogHeader>
      <DialogFooter>
        <Button disabled={selectedAssignmentCount === 0} onClick={() => setPage(1)}>
          Next
        </Button>
      </DialogFooter>
    </>
  )
}

export default SelectAssignments
