import { DataTable } from "@components/Tables/DataTable.tsx"
import { UsersTableColumns } from "@components/Widgets/UsersTable/UsersTableColumns.tsx"

import useManageUserStore from "@features/Admin/Users/stores/ManageUsersStore.ts"

const UsersTable = () => {
  const users = useManageUserStore((state) => state.users)

  // const ActionButtons = [<AddUserDialog />]
  const ActionButtons = [] as React.ReactNode[]

  return <DataTable title={"All Users"} columns={UsersTableColumns} data={users} ActionButtonsSection={ActionButtons} />
}

export default UsersTable
