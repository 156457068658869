import HabitSection from "./HabitSection"

const HabitPage: React.FC = () => {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="w-full">
          <h3>Habit</h3>
        </div>
  
        <HabitSection />
      </div>
    )
  }
  
  export default HabitPage
  