import { create } from "zustand"

interface SurveyStore {
  surveySets: TemplateFeedItem[]
  surveys: Survey[]
  setSurveySets: (surveySets: TemplateFeedItem[]) => void
  setSurveys: (surveys: Survey[]) => void
}

const useSurveyStore = create<SurveyStore>((set) => ({
  surveySets: [],
  surveys: [],
  setSurveySets: (surveySets) => set({ surveySets }),
  setSurveys: (surveys) => set({ surveys }),
}))

export default useSurveyStore
