import { useEffect, useRef, useState } from "react"

import { useInvalidateQuery } from "@utilities/invalidateQueries.ts"

import MoodProvider from "../provider/MoodProvider.tsx"
import useMoodResponseStore from "../stores/MoodResponseStore.ts"
import MoodColorGraph from "./MoodColorGraph.tsx"
import MoodDisc from "./MoodDisc.tsx"

interface MoodSelectorProps {
  item_payload?: FeedItemPayload | null
  buttonLabel?: string
  onClick?: () => void
  disabled?: boolean
  description?: string
  completed_at?: string | null
}

const MoodSelector: React.FC<MoodSelectorProps & FeedItemComponent> = ({
  feed_item_interaction_id,
  item_payload,
  completed_at,
}) => {
  const payload = item_payload as MoodFeedItemPayload
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0, distance: 0, angle: 0 })
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const [isHovering, setIsHovering] = useState(false)
  const [isEmotionSelected, setIsEmotionSelected] = useState(false)
  const [tooltipLocked, setTooltipLocked] = useState(false)
  const imageRef = useRef<HTMLImageElement>(null)

  const [selectedMood, setSelectedMood] = useState("")
  const [selectedIntensity, setSelectedIntensity] = useState(0.0)
  const [selectedAngle, setSelectedAngle] = useState(0)
  const neutral_range = 32
  const isCompleted = completed_at !== null
  const containerStyle =
    "relative flex flex-col w-full justify-center items-center h-100 rounded-lg bg-neutral-50 min-w-96"

  // Mood Response Store
  const setMoodName = useMoodResponseStore((state) => state.setMoodName)
  const setAngle = useMoodResponseStore((state) => state.setAngle)
  const setIntensity = useMoodResponseStore((state) => state.setIntensity)
  const setQuestionResponses = useMoodResponseStore((state) => state.setQuestionResponses)
  const postResponse = useMoodResponseStore((state) => state.postResponse)
  const completeFeedItem = useMoodResponseStore((state) => state.completeFeedItem)

  // Query Client for invalidating the query
  const invalidateCalendarData = useInvalidateQuery(["calendarData"], 3000)
  const invalidateFeedData = useInvalidateQuery(["feedData"], 3000)

  // additional question response
  const [userResponse, setUserResponse] = useState("")

  const handleMouseEnter = () => setIsHovering(true)
  const handleMouseLeave = () => setIsHovering(false)
  const handleMouseClick = () => {
    if (!tooltipLocked && mousePosition.distance < imageSize.width / 2) {
      setTooltipLocked(true)
      setSelectedMood(getMood(mousePosition.angle, mousePosition.distance))
      setSelectedIntensity(getIntensity(mousePosition.distance))
      setSelectedAngle(addJustAngle(mousePosition.angle))
    }
  }
  const handleReset = () => {
    setTooltipLocked(false)
    setIsEmotionSelected(false)
  }

  const saveQuestionRespnseToStore = (answer: string) => {
    setUserResponse(answer)
    const answers = payload.mood_questions as MoodAnswer[]
    answers[0].answer = answer
    setQuestionResponses(answers)
  }

  const handleNextClick = () => {
    setMoodName(selectedMood)
    setAngle(selectedAngle)
    setIntensity(selectedIntensity)
    setIsEmotionSelected(true)
  }

  const handleSubmit = () => {
    postResponse()
    completeFeedItem(feed_item_interaction_id)
    setTooltipLocked(false)
    setIsEmotionSelected(false)
    void invalidateCalendarData()
    void invalidateFeedData()
  }

  const emotionIndexMap = {
    0: "Distressed",
    1: "Angry",
    2: "Tense",
    3: "Alert",
    4: "Excited",
    5: "Happy",
    6: "Content",
    7: "Relaxed",
    8: "Calm",
    9: "Bored",
    10: "Depressed",
    11: "Sad",
    12: "Neutral",
  }

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const touch = event.touches[0]
    updateTouchPosition(touch.clientX, touch.clientY)
  }

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setIsHovering(true)
    const touch = event.touches[0]
    updateTouchPosition(touch.clientX, touch.clientY)
  }

  const handleTouchEnd = () => {
    setIsHovering(false)
    if (!tooltipLocked && mousePosition.distance < imageSize.width / 2) {
      setTooltipLocked(true)
      setSelectedMood(getMood(mousePosition.angle, mousePosition.distance))
      setSelectedIntensity(getIntensity(mousePosition.distance))
      setSelectedAngle(addJustAngle(mousePosition.angle))
    }
  }

  const updateTouchPosition = (clientX: number, clientY: number) => {
    if (!imageRef.current) return
    const rect = imageRef.current.getBoundingClientRect()
    calculateAndUpdateMousePosition(clientX, clientY, rect)
  }

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = event.currentTarget.getBoundingClientRect()
    calculateAndUpdateMousePosition(event.clientX, event.clientY, rect)
  }

  const calculateAndUpdateMousePosition = (clientX: number, clientY: number, rect: DOMRect) => {
    const centerX = rect.width / 2
    const centerY = rect.height / 2
    const mouseX = clientX - rect.left
    const mouseY = clientY - rect.top

    const dx = mouseX - centerX
    const dy = mouseY - centerY
    const distance = Math.sqrt(dx * dx + dy * dy)
    const angle = Math.atan2(dy, dx) * (180 / Math.PI) + 180 // Convert radians to degrees

    setMousePosition({
      x: mouseX,
      y: mouseY,
      distance: Math.round(distance),
      angle: Math.round(angle),
    })
  }

  const getMood = (angle: number, distance: number): string => {
    if (distance < neutral_range) {
      return "Neutral"
    }

    const index = Math.min(Math.floor(angle / 30), 11) // Ensure the index does not exceed 11
    return emotionIndexMap[index as keyof typeof emotionIndexMap]
  }

  const getIntensity = (distance: number): number => {
    const addjusted_distance = distance - neutral_range
    if (addjusted_distance < 0) return 0 // mood should be neutral
    let intensity = Math.min(addjusted_distance / 10, 9) + 1
    intensity = intensity / 10
    intensity = Math.round(intensity * 100) / 100
    return intensity
  }

  const addJustAngle = (angle: number): number => {
    return (angle - 90 + 360) % 360
  }

  useEffect(() => {
    if (imageRef.current) {
      const { height } = imageRef.current.getBoundingClientRect()
      // since width is responsive, we can calculate width based on height
      // 0.85 is the padding ratio
      setImageSize({ width: Math.round(height * 0.85), height: Math.round(height * 0.85) })
    }
  }, [])

  if (isCompleted) {
    return (
      <div className={containerStyle}>
        <MoodProvider>
          <MoodColorGraph />
        </MoodProvider>
      </div>
    )
  }
  return (
    <div className={containerStyle}>
      <div className="relative z-10 text-xs font-light p-4">
        {isEmotionSelected ? "" : "Select the area that best describes how you feel right now"}
      </div>
      {!isEmotionSelected ? (
        <div
          className="flex justify-center items-center w-96 h-full relative mb-4"
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleMouseClick}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{ touchAction: "none" }}
        >
          <MoodDisc
            imageRef={imageRef}
            isHovering={isHovering}
            tooltipLocked={tooltipLocked}
            selectedMood={selectedMood}
            selectedIntensity={selectedIntensity}
            handleReset={handleReset}
            handleNextClick={handleNextClick}
          />
          {isHovering &&
            !tooltipLocked &&
            mousePosition.distance < imageSize.height / 2 && ( // TODO: Change the distance value to dynamic value
              <div>
                <div
                  className="absolute p-2 bg-black rounded-full text-nowrap flex w-44 justify-between items-center"
                  style={{
                    left: `${mousePosition.x}px`,
                    top: `${mousePosition.y}px`,
                    transform: "translate(-50%, -150%)",
                  }}
                >
                  <div className="text-white text-lg font-light w-full px-3 justify-between flex">
                    <span className="font-black">{getMood(mousePosition.angle, mousePosition.distance)}</span>
                    <span>{Math.floor(getIntensity(mousePosition.distance) * 10)}</span>
                  </div>
                </div>
                <div
                  className="absolute p-2 bg-white rounded-full shadow"
                  style={{
                    left: `${mousePosition.x}px`,
                    top: `${mousePosition.y}px`,
                    transform: "translate(-50%, -50%)",
                  }}
                ></div>
              </div>
            )}
        </div>
      ) : (
        <div className="flex flex-col items-center w-full h-full">
          <div className="flex flex-1 flex-col w-full justify-center mt-4 items-center">
            <div className="mb-2">{payload.mood_questions[0].question_text}</div>
            <textarea
              placeholder="Type your feelings here..."
              value={userResponse}
              onChange={(e) => saveQuestionRespnseToStore(e.target.value)}
              className="w-5/6 h-full p-3 text-lg border rounded-lg resize-none focus:outline-black"
            ></textarea>
          </div>
          <div className="flex flex-row gap-4 justify-center mt-4 mb-8">
            <button onClick={handleReset} className="m-2 p-2 bg-gray-200 w-24 rounded-full">
              Back
            </button>
            <button onClick={handleSubmit} className="m-2 p-2 bg-black w-24 text-white rounded-full">
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MoodSelector
