import { ColumnDef } from "@tanstack/react-table"

import { DataTableColumnHeader } from "@components/Tables/DataTable.tsx"
import UsersTableDropdownMenu from "@components/Widgets/UsersTable/UsersTableDropdownMenu.tsx"

export const UsersTableColumns: ColumnDef<ListUser>[] = [
  {
    id: "Display Name",
    accessorKey: "display_name",
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Name"} />,
  },

  {
    accessorKey: "email",
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Email"} />,
  },
  {
    id: "Role",
    accessorKey: "roles",
    header: "Roles",
    cell: ({ row }) => {
      const user = row.original
      return (
        <div>
          {user.roles.map((role) => (
            <span key={role} className="capitalize text-xs bg-gray-200 text-gray-600 rounded-full px-2 py-1 mr-1">
              {role}
            </span>
          ))}
        </div>
      )
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <UsersTableDropdownMenu row={row} />,
  },
]
