import { Checkbox } from "@/shadcn-components/ui/checkbox.tsx"
import { ExtendedColumnDef } from "@/types/extensions/ExtendedColumnDef"

import { DataTableColumnHeader } from "@components/Tables/DataTable.tsx"

export const AddAssignmentsListColumns: ExtendedColumnDef<AdminAssignmentItem>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Name"} />,
    accessorKey: "name",
  },
  {
    id: "Section Key",
    header: "Section",
    showByDefault: false,
    accessorKey: "section_key",
    cell: ({ row }) => {
      const { section_key } = row.original
      return (
        <div>
          <span key={section_key} className="capitalize text-xs bg-gray-200 text-gray-600 rounded-full px-2 py-1 mr-1">
            {section_key}
          </span>
        </div>
      )
    },
  },
  {
    header: "Content Type",
    accessorKey: "Content Type",
    cell: ({ row }) => {
      const { content_type } = row.original
      return (
        <div>
          <span key={content_type} className="capitalize text-xs bg-blue-400 text-blue-50 rounded-full px-2 py-1 mr-1">
            {content_type}
          </span>
        </div>
      )
    },
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Org"} />,
    accessorKey: "template_feed_item.org_key",
    enableSorting: true,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Target Email"} />,
    accessorKey: "target_user.email",
    enableSorting: true,
  },
  {
    header: "Default Recurrence",
    accessorKey: "human_readable_recurrence",
  },
]
