import { faCircle, faCircle as faHollowCircle, faMinus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface HabitScaleDotsProps {
  scale: number | null
}

const HabitScaleDots: React.FC<HabitScaleDotsProps> = ({ scale: value }) => {
  const renderDots = () => {
    if (value === null) {
      return (
        <div className="flex justify-center items-center">
          <FontAwesomeIcon icon={faMinus} /> {/* Dash icon */}
        </div>
      )
    }

    const dots = []
    for (let i = 0; i < 4; i++) {
      if (i < value - 1) {
        dots.push(
          <FontAwesomeIcon
            key={i}
            icon={faCircle}
            className="text-gray-800" // Filled dot
          />,
        )
      } else {
        dots.push(
          <FontAwesomeIcon
            key={i}
            icon={faHollowCircle}
            className="text-gray-200" // Hollow dot
          />,
        )
      }
    }
    return <div className="flex flex-row justify-start items-center gap-0.5">{dots}</div>
  }

  return <div className="flex flex-row max-w-[60px] text-[8px] ">{renderDots()}</div>
}

export default HabitScaleDots
