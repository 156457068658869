import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import useManageUserStore from "@features/Admin/Users/stores/ManageUsersStore.ts"

import api from "@utilities/api.ts"

const ManageUsersProvider = ({ children }: ProviderBaseProps) => {
  const setUsers = useManageUserStore((state) => state.setUsers)
  const { data, isPending, isError, error } = useQuery<ListUser[]>({
    retry: false,
    queryKey: ["users"],
    queryFn: async () => {
      const response = await api.get<ListUser[]>("/admin/users")
      return response.data
    },
  })

  useEffect(() => {
    if (data) {
      setUsers(data)
    }
  }, [setUsers, data])
  if (isPending) return <div>Loading...</div>
  if (isError) return <div>Error: {error.message}</div>
  return children
}

export default ManageUsersProvider
