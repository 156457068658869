import { create } from "zustand"

interface StepSummaryStore {
  currentStepsResponse: StepsSummaryResponse | null
  setCurrentStepsResponse: (response: StepsSummaryResponse) => void
}

const useStepSummaryStore = create<StepSummaryStore>((set) => ({
  currentStepsResponse: null,
  setCurrentStepsResponse: (currentStepsResponse) => set({ currentStepsResponse }),
}))

export default useStepSummaryStore
