import * as d3 from "d3"

import { brightenColor } from "./utils"

class ThumbnailGraphD3 {
  constructor(element, width, height, data, color = "#5E89FF") {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 20, right: 25, bottom: 20, left: 25 }
    this.svg = null
    this.unit = data[0].unit
    this.color = color

    this.createChart()
  }

  createChart() {
    // Remove the old chart if it exists
    d3.select(this.element).select("svg").remove()

    // Configuration options for the graph
    const showAllDots = true
    const showDashLines = true

    // Set up SVG canvas dimensions
    const svg = d3
      .select(this.element)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    this.svg = svg

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    const parseDate = d3.timeParse("%Y-%m-%d")

    // Prepare the data, setting zero values to null
    // TODO: 10-29-2024 Remove this when the backend is updated to send null values instead of zeros
    const data = this.data.map((d) => ({
      date: parseDate(d.date),
      value: d.value === 0 ? null : d.value,
    }))

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.date))
      .range([0, innerWidth])

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, (d) => d.value), d3.max(data, (d) => d.value)])
      .nice()
      .range([innerHeight, 0])

    // Define the main line that skips null values
    const line = d3
      .line()
      .defined((d) => d.value !== null)
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(d3.curveMonotoneX)

    // Append the gradient definition
    const gradient = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", `line-gradient-activity-summary-${this.color}`)
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", innerHeight)
      .attr("x2", 0)
      .attr("y2", 0)

    gradient.append("stop").attr("offset", "0%").attr("stop-color", this.color).attr("stop-opacity", 0)
    gradient.append("stop").attr("offset", "100%").attr("stop-color", this.color).attr("stop-opacity", 0.3)

    // Append the main area
    const area = d3
      .area()
      .defined((d) => d.value !== null)
      .x((d) => xScale(d.date))
      .y0(innerHeight)
      .y1((d) => yScale(d.value))
      .curve(d3.curveMonotoneX)

    svg.append("path").datum(data).attr("fill", `url(#line-gradient-activity-summary-${this.color})`).attr("d", area)

    // Append the main line path (solid line that skips null values)
    svg
      .append("path")
      .datum(data)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", this.color)
      .attr("stroke-width", 1)
      .style("opacity", 1)
      .attr("d", line)

    // Draw a tiny dot for each non-zero (non-null) data
    if (showAllDots) {
      svg
        .selectAll(".dot-all")
        .data(data.filter((d) => d.value !== null))
        .enter()
        .append("circle")
        .attr("class", "dot-all")
        .attr("cx", (d) => xScale(d.date))
        .attr("cy", (d) => yScale(d.value))
        .attr("r", 2) // Radius for tiny dots
        .attr("fill", this.color)
    }

    // Draw a tiny dot for each non-zero (non-null) data point with missing data on either side
    if (!showAllDots) {
      svg
        .selectAll(".dot-missing-neighbor")
        .data(
          data.filter((d, i) => {
            const hasMissingNeighbor =
              (i > 0 && data[i - 1].value === null) || (i < data.length - 1 && data[i + 1].value === null)
            return d.value !== null && hasMissingNeighbor
          }),
        )
        .enter()
        .append("circle")
        .attr("class", "dot-missing-neighbor")
        .attr("cx", (d) => xScale(d.date))
        .attr("cy", (d) => yScale(d.value))
        .attr("r", 2) // Radius for tiny dots
        .attr("fill", this.color)
    }

    // Draw dashed lines between valid data points with gaps in between
    if (showDashLines) {
      let lastValidPoint = null
      data.forEach((point) => {
        if (point.value !== null) {
          // Draw dashed line from last valid point to current point if there's a gap
          if (
            lastValidPoint &&
            data.slice(data.indexOf(lastValidPoint) + 1, data.indexOf(point)).some((d) => d.value === null)
          ) {
            svg
              .append("line")
              .attr("x1", xScale(lastValidPoint.date))
              .attr("y1", yScale(lastValidPoint.value))
              .attr("x2", xScale(point.date))
              .attr("y2", yScale(point.value))
              .attr("stroke", this.color)
              .attr("stroke-width", 1)
              .attr("stroke-linecap", "round")
              .attr("stroke-dasharray", "2, 3") // Dashed line pattern
              .style("opacity", 0.5)
          }
          lastValidPoint = point
        }
      })
    }

    // Tooltip
    const tooltip = d3
      .select(this.element)
      .append("div")
      .attr("class", "thumbnail-tooltip")
      .style("position", "absolute")
      .style("padding", "4px 12px")
      .style("background", "#000")
      .style("border-radius", "20px")
      .style("color", "#fff")
      .style("font-size", "12px")
      .style("pointer-events", "none")
      .style("opacity", 0)

    // Filter out data points with a value of null
    const nonZeroData = data.filter((d) => d.value !== null)

    // Find max and min data points, ignoring zeros
    const maxDataPoint = d3.max(nonZeroData, (d) => d.value)
    const minDataPoint = d3.min(nonZeroData, (d) => d.value)

    // Select max and min data points from the filtered data
    const maxPoint = nonZeroData.find((d) => d.value === maxDataPoint)
    const minPoint = nonZeroData.find((d) => d.value === minDataPoint)

    if (maxPoint && minPoint) {
      // Add circles for max and min points
      svg
        .append("circle")
        .attr("cx", xScale(maxPoint.date))
        .attr("cy", yScale(maxPoint.value))
        .attr("r", 4)
        .attr("fill", this.color)

      svg
        .append("circle")
        .attr("cx", xScale(minPoint.date))
        .attr("cy", yScale(minPoint.value))
        .attr("r", 4)
        .attr("fill", this.color)

      // Add text for max and min values
      svg
        .append("text")
        .attr("x", xScale(maxPoint.date))
        .attr("y", yScale(maxPoint.value) - 8)
        .attr("text-anchor", "middle")
        .attr("font-size", "10px")
        .attr("font-family", "sans-serif")
        .attr("fill", "#888888")
        .text(`${Number.isInteger(maxPoint.value) ? maxPoint.value : maxPoint.value.toFixed(1)}${this.unit}`)

      svg
        .append("text")
        .attr("x", xScale(minPoint.date))
        .attr("y", yScale(minPoint.value) + 16)
        .attr("text-anchor", "middle")
        .attr("font-size", "10px")
        .attr("font-family", "sans-serif")
        .attr("fill", "#888888")
        .text(`${Number.isInteger(minPoint.value) ? minPoint.value : minPoint.value.toFixed(1)}${this.unit}`)
    }

    // Vertical line for highlighting
    const focusLine = svg
      .append("line")
      .attr("class", "focusLine")
      .attr("stroke", "#000")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3")
      .style("opacity", 0)

    // Circle for highlighting
    const focusCircle = svg.append("circle").attr("r", 4).attr("fill", "#000").style("opacity", 0)

    // Add interaction layer for tooltip
    svg
      .append("rect")
      .attr("class", "overlay")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mousemove", (event) => this.handleMouseMove(event, data, xScale, yScale, tooltip, focusLine, focusCircle))
      .on("mouseout", () => {
        tooltip.style("opacity", 0)
        focusLine.style("opacity", 0)
        focusCircle.style("opacity", 0)
      })
  }

  handleMouseMove(event, data, xScale, yScale, tooltip, focusLine, focusCircle) {
    const [xPos] = d3.pointer(event)
    const xDate = xScale.invert(xPos)
    const bisectDate = d3.bisector((d) => d.date).left
    const index = bisectDate(data, xDate, 1)
    const d = data[index - 1] || data[0]

    // Format date to "MM/DD"
    const formatDate = d3.timeFormat("%m/%d")

    // Show the tooltip
    tooltip
      .html(
        `<span style="color: ${brightenColor(this.color, 0.5)};">${formatDate(d.date)}</span> ${
          d.value === null ? "? " : Number.isInteger(d.value) ? d.value : d.value.toFixed(1)
        }${this.unit}`,
      )
      .style("left", `${event.pageX - 40}px`)
      .style("top", `${event.pageY - 30}px`)
      .style("opacity", 1)

    // Show the vertical line
    focusLine
      .attr("x1", xScale(d.date))
      .attr("x2", xScale(d.date))
      .attr("y1", 0 - this.margin.top)
      .attr("y2", this.height)
      .style("opacity", 0.2)

    // Show the focus circle
    if (d.value !== null && d.value !== 0) {
      focusCircle.attr("cx", xScale(d.date)).attr("cy", yScale(d.value)).style("opacity", 1)
    } else {
      focusCircle.style("opacity", 0) // Hide the focus circle if the condition is not met
    }
  }
}

export default ThumbnailGraphD3
