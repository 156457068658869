import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import ThumbnailGraphD3 from "./D3Files/ThumbnailGraphD3.js"

interface ThumbnailGraphProps {
  graphData?: ThumbnailGraphData[]
  color?: string
}

const ThumbnailGraph = ({ graphData = [], color = "#5E89FF" }: ThumbnailGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const currentGraphData = graphData
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const hasValidData = graphData?.length > 0

  useEffect(() => {
    if (isValidSize && hasValidData) {
      new ThumbnailGraphD3(chartArea.current, containerSize.width, containerSize.height, currentGraphData, color)
    }
  }, [containerSize, graphData, currentGraphData, isValidSize, hasValidData, color])

  return (
    <div className="w-full h-auto overflow-hidden" ref={containerRef}>
      <div className="chart-area flex-1 w-full h-20 overflow-hidden" ref={chartArea}></div>
    </div>
  )
}

export default ThumbnailGraph
