import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import useAddAssignmentStore from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsStore.ts"

import api from "@utilities/api.ts"

interface Props {
  children: React.ReactNode
  targetUserId: string
}

const AddAssignmentsProvider = ({ children, targetUserId }: Props) => {
  const setAvailableAssignments = useAddAssignmentStore((state) => state.setAvailableAssignments)

  const { data, isPending, isError, error } = useQuery({
    queryKey: ["assignments", targetUserId],
    queryFn: async () => {
      const response = await api.get<AdminAssignmentItem[]>(`/admin/assignments?target_user_id=${targetUserId}`)
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) setAvailableAssignments(data)
  }, [data, setAvailableAssignments])

  if (isPending) return <div>Loading...</div>
  if (isError) return <div>Error: {error.message}</div>

  return children
}

export default AddAssignmentsProvider
