import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import useManageCliniciansStore from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ManageCliniciansStore.ts"

import api from "@utilities/api.ts"

interface Props {
  children: React.ReactNode
}

const ManageCliniciansProvider = ({ children }: Props) => {
  const setClinicians = useManageCliniciansStore((state) => state.setClinicians)

  const { data, isPending, isError, error } = useQuery({
    queryKey: ["providers"],
    queryFn: async () => {
      const response = await api.get<AdminClinicianItem[]>("/admin/providers")
      if (response.status === 200 && response.data) return response.data
      throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    if (data) setClinicians(data)
  }, [data, setClinicians])

  if (isPending) return <div>Loading...</div>
  if (isError) return <div>Error: {error.message}</div>

  return children
}

export default ManageCliniciansProvider
