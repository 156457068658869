import { create } from "zustand"

import api from "../../../utilities/api.ts"

interface PrivateSettingsType {
  user_id: string
  email_notify_ok: boolean
  text_notify_ok: boolean
  [key: string]: string | boolean
}
interface PrivateSettingsStore {
  settings: PrivateSettingsType
  fetchSettings: () => Promise<void>
}

type PrivateSettings = PrivateSettingsStore & FormBuilder

const usePrivateSettingsStore = create<PrivateSettings>()((set, get) => ({
  hasChanged: false,
  settings: {
    user_id: "",
    email_notify_ok: false,
    text_notify_ok: false,
  },
  setter: (key: string, value: string | boolean) => {
    set((state) => {
      return { settings: { ...state.settings, [key]: value }, hasChanged: true }
    })
  },
  fetchSettings: async () => {
    const response = await api.get<PrivateSettingsType[]>("/private/settings/self")
    if (response.status === 200 && response.data[0]) {
      set(() => ({ settings: response.data[0] }))
    }
  },
  handleSave: async () => {
    const { user_id, ...settings } = get().settings
    const response = await api.put(`/private/settings/self/${user_id}`, settings)
    if (response.status === 200) {
      set(() => ({ hasChanged: false }))
    }
  },
}))

export default usePrivateSettingsStore
