import ActivityGraph from "../graph/ActivityGraph"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import DailySleepActivityProvider from "./provider/DailySleepActivityProvider"
import DailyStepsActivityProvider from "./provider/DailyStepsActivityProvider"

const DailySleepAndStepsCard: React.FC = () => {
  const { currentStepsResponse } = useStepSummaryStore()
  const stepsChartData = currentStepsResponse?.chart_data || []

  const { currentSleepResponse } = useSleepSummaryStore()
  const sleepChartData = currentSleepResponse?.chart_data || []

  return (
    <div className="flex gap-3 flex-col">
      <DailySleepActivityProvider>
        <ActivityGraph graphData={sleepChartData} name="Hours Slept" color="#5E89FF" />
      </DailySleepActivityProvider>
      <DailyStepsActivityProvider>
        <ActivityGraph graphData={stepsChartData} name="Daily Steps" color="#01B85E" />
      </DailyStepsActivityProvider>
    </div>
  )
}

export default DailySleepAndStepsCard
