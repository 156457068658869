import ActivityStackGraph from "../graph/ActivityStackGraph"
import useActivityInsightStore from "./store/ActivityInsightStore"

const SleepAndStepsCard: React.FC = () => {
  const currentGraphData = useActivityInsightStore((state) => state.currentActivityResponse)
  if (!currentGraphData) return null

  return <ActivityStackGraph graphData={currentGraphData} />
}

export default SleepAndStepsCard
