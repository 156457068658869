import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"

// import DensityGraphAnimated from "../graph/DensityGraphAnimated"

const MoodMapSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <>
      <SectionContainer title="Mood Map" description="All stats latest available score within the last 3 weeks">
        <div className="flex gap-3">
          <Card additionalClasses={cardStyle} padding="p-2">
            <div className="w-full aspect-square">
              <div className="text-xs text-neutral-300">Feature Coming Soon.</div>
            </div>
          </Card>
          <Card additionalClasses={cardStyle} padding="p-2">
            <div className="w-full aspect-square"></div>
          </Card>
          <Card additionalClasses={cardStyle} padding="p-2">
            <div className="w-full aspect-square"></div>
          </Card>
        </div>
      </SectionContainer>
    </>
  )
}

export default MoodMapSection
