import { create } from "zustand"

interface SurveySummaryStore {
  currentSurveyResponse: SurveySummaryResponse | null
  setCurrentSurveyResponse: (response: SurveySummaryResponse) => void
}

const useSurveySummaryStore = create<SurveySummaryStore>((set) => ({
  currentSurveyResponse: null,
  setCurrentSurveyResponse: (currentSurveyResponse) => set({ currentSurveyResponse }),
}))

export default useSurveySummaryStore
