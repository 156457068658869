import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"
import { useInvalidateQuery } from "@utilities/invalidateQueries.ts"

import SectionContainer from "../../atom/SectionContainer.tsx"
import useSummaryHeaderStore from "../store/SummaryHeaderStore.ts"

const SummaryHeaderProvider = ({ children }: { children: ReactNode }) => {
  const invalidateInsightData = useInvalidateQuery(["calendarData"], 3000)
  const setCurrentSummaryHeader = useSummaryHeaderStore((state) => state.setCurrentSummaryHeaderResponse)
  const { clientId } = useParams()

  const {
    data: summaryHeaderResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["feedData", clientId],
    queryFn: async () => {
      const endpoint = `insight/header/${clientId}`
      const { data, status } = await api.get<SummaryHeaderResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Feed data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && summaryHeaderResponse) {
      setCurrentSummaryHeader(summaryHeaderResponse)
    }
  }, [summaryHeaderResponse, isSuccess, setCurrentSummaryHeader])

  useEffect(() => {
    void invalidateInsightData()
  }, [clientId, invalidateInsightData])

  if (isLoading)
    return (
      <SectionContainer marginSize="lg" title="Patient Profile">
        <LoadingSpinner size="2x" />
      </SectionContainer>
    )
  if (isError) return <div className="mt-6">Failed to load patient profile</div>

  return children
}

export default SummaryHeaderProvider
