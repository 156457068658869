import AdminCover from "@/assets/images/admin-cover.jpg"

import CardGrid from "@components/Cards/CardGrid.tsx"

import HeaderCard from "@features/Calendar/HeaderCard.tsx"

const AdminHome = () => {
  const cards: CardsManifestCard[] = [
    {
      content: <HeaderCard coverImage={AdminCover} />,
      width: 12,
      backgroundColor: "white",
      textColor: "black",
    },
  ]

  return <CardGrid cards={cards} />
}

export default AdminHome
