import { create } from "zustand"

interface ActivityInsightStore {
  currentActivityResponse: ActivityStackResponse | null
  setCurrentActivityResponse: (response: ActivityStackResponse) => void
}

const useActivityInsightStore = create<ActivityInsightStore>((set) => ({
  currentActivityResponse: null,
  setCurrentActivityResponse: (currentActivityResponse) => set({ currentActivityResponse }),
}))

export default useActivityInsightStore
