const HabitsItemRowHeader: React.FC = () => {
  return (
    <div className="flex flex-row justify-between items-center w-full mb-3">
      <div className="min-w-[100px] text-xs text-neutral-300 items-center">Day</div>
      <div className="min-w-[60px] text-xs text-neutral-300">Meds</div>
      <div className="min-w-[60px] text-xs text-neutral-300">Alcohol</div>
      <div className="min-w-[60px] text-xs text-neutral-300">Canna</div>
      <div className="min-w-[60px] text-xs text-neutral-300">Caffeine</div>
      <div className="min-w-[60px] text-xs text-neutral-300">Screen</div>
      <div className="min-w-[60px] text-xs text-neutral-300">Junk Food</div>
    </div>
  )
}

export default HabitsItemRowHeader
