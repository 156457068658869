import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import SurveysSummaryContent from "./SurveysSummaryContent"
import SurveysSummaryProvider from "./provider/SurveysSummaryProvider"

const SurveysSummarySection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <SectionContainer title="Surveys" description="All stats represent latest available score">
      <div className="flex gap-3">
        <Card additionalClasses={cardStyle}>
          <SurveysSummaryProvider>
            <SurveysSummaryContent />
          </SurveysSummaryProvider>
        </Card>
      </div>
    </SectionContainer>
  )
}

export default SurveysSummarySection
