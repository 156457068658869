import { Row } from "@tanstack/react-table"
import { MoreHorizontal } from "lucide-react"

import { config } from "@/config.ts"
import { Button } from "@/shadcn-components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shadcn-components/ui/dropdown-menu.tsx"

import AddAssignmentsDialog from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignmentsDialog.tsx"
import ManageCliniciansDialog from "@components/Widgets/UsersTable/DropdownMenuItems/ManageCliniciansDialog.tsx"

import { success } from "@utilities/logger.ts"
import { useDialog } from "@utilities/shadcn-utils.ts"

interface Props {
  row: Row<ListUser>
}

const UsersTableDropdownMenu = ({ row }: Props) => {
  const user = row.original

  const copyUserId = async () => {
    await navigator.clipboard.writeText(user.user_id)
    success("User ID copied to clipboard")
  }

  const assignClinicianDialog = useDialog()
  const addAssignmentsDialog = useDialog()

  const clerkUrl = `https://dashboard.clerk.com/apps/${config.clerk.app_id}/instances/${config.clerk.instance_id}/users/${user.idp_sub}`

  return (
    <>
      {/* -- Dialogs -- */}
      <ManageCliniciansDialog row={row} triggerProps={assignClinicianDialog.props} />
      <AddAssignmentsDialog row={row} triggerProps={addAssignmentsDialog.props} />
      {/* -- End Dialogs -- */}

      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => void copyUserId()}>Copy user ID</DropdownMenuItem>
          <DropdownMenuSeparator />
          {/*<DropdownMenuLabel>Manage User</DropdownMenuLabel>*/}
          <DropdownMenuItem onSelect={addAssignmentsDialog.trigger}>Add Assignments</DropdownMenuItem>
          <DropdownMenuItem onSelect={assignClinicianDialog.trigger}>Assign Clinician</DropdownMenuItem>
          {/*<DropdownMenuItem>Add to Org</DropdownMenuItem>*/}
          <DropdownMenuSeparator />
          {/*<DropdownMenuItem>View user</DropdownMenuItem>*/}
          <a target={"_blank"} rel={"noopener noreferrer"} href={clerkUrl}>
            <DropdownMenuItem>Open in Clerk</DropdownMenuItem>
          </a>
          {/*<DropdownMenuSeparator />*/}
          {/*<DropdownMenuItem className={"text-red-400"}>Deidentify User</DropdownMenuItem>*/}
          {/*<DropdownMenuItem className={"text-red-400"}>Delete User</DropdownMenuItem>*/}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
export default UsersTableDropdownMenu
