import { TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs"

const InsightReportTabs = () => {
  const tabClasses =
    "bg-neutral-50 rounded-full text-neutral-400\
    hover:bg-white\
    data-[state=active]:bg-white data-[state=active]:shadow data-[state=active]:text-black"
  const tabWrapperClasses = "bg-neutral-50 rounded-full w-full flex justify-between max-w-xl items-center my-8"

  return (
    <TabsList className={tabWrapperClasses}>
      <TabsTrigger className={tabClasses} value={"summary"}>
        Summary
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"activity"}>
        Activity
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"surveys"}>
        Surveys
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"habits"}>
        Habits
      </TabsTrigger>
      <TabsTrigger className={tabClasses} value={"mood"}>
        Mood
      </TabsTrigger>
    </TabsList>
  )
}

export default InsightReportTabs
