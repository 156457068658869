import { useState } from "react"

import { Button } from "@/shadcn-components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/shadcn-components/ui/dialog.tsx"

import ManageCliniciansList from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ManageCliniciansList.tsx"
import ManageCliniciansProvider from "@components/Widgets/UsersTable/DropdownMenuItems/ManageClinicians/ManageCliniciansProvider.tsx"

import api from "@utilities/api.ts"
import { success } from "@utilities/logger.ts"

const ManageCliniciansDialog = ({ row, triggerProps }: DropdownTriggeredDialogProps<ListUser>) => {
  const [rowSelection, setRowSelection] = useState<AdminClinicianItem[]>([])

  const handleSubmit = async () => {
    const userIds = rowSelection.map((user: AdminClinicianItem) => user.user_id)
    const { data } = await api.post<{ message: string }>(`/admin/users/${row.original.user_id}/clinicians`, {
      providers: userIds,
    })
    success(data.message)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    triggerProps.onOpenChange()
  }

  // conditionally render if this is open. if we don't, then we'll end up fetching the data for no reason
  if (triggerProps?.open)
    return (
      <Dialog {...triggerProps}>
        <DialogContent className={"w-[700px] max-w-full"}>
          <DialogHeader>
            <DialogTitle>Assign Clinicians to Patient</DialogTitle>
            <DialogDescription>
              Adding clinicians to a patient will allow them to view and interact with the patient's data.
            </DialogDescription>
          </DialogHeader>
          <ManageCliniciansProvider>
            <ManageCliniciansList setRowSelection={setRowSelection} />
            {rowSelection.length > 0 && (
              <div className={"text-sm text-gray-500 mt-2"}>{rowSelection.length} clinicians selected</div>
            )}
          </ManageCliniciansProvider>
          <DialogFooter className={"mt-4"}>
            <Button onClick={() => void handleSubmit()} type="submit">
              Assign
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )

  return null
}

export default ManageCliniciansDialog
