import { InfoCircledIcon } from "@radix-ui/react-icons"
import { ReactNode } from "react"

import { CardDescription, CardTitle } from "@/shadcn-components/ui/card.tsx"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/shadcn-components/ui/tooltip.tsx"

interface Props {
  label: string
  title: string
  description: string | ReactNode
}

const TooltipTableHeader = ({ label, title, description }: Props) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className={"flex items-center"}>
            <span>{label}</span>
            <InfoCircledIcon className="w-4 h-4 ml-1 text-gray-400" />
          </div>
        </TooltipTrigger>
        <TooltipContent side={"bottom"} className={"p-4 max-w-[320px]"}>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default TooltipTableHeader
