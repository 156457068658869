import { Dispatch, SetStateAction } from "react"

import { DataTable } from "@components/Tables/DataTable.tsx"
import { AddAssignmentsListColumns } from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsListColumns.tsx"
import useAddAssignmentStore from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsStore.ts"

const AddAssignmentsList = () => {
  const availableAssignments = useAddAssignmentStore((state) => state.availableAssignments)
  const transformAssignments = useAddAssignmentStore((state) => state.transformAssignments)
  return (
    <DataTable
      columns={AddAssignmentsListColumns}
      data={availableAssignments}
      setSelectedRows={transformAssignments as Dispatch<SetStateAction<AdminAssignmentItem[]>>}
    />
  )
}

export default AddAssignmentsList
