import SectionContainer from "../atom/SectionContainer"
import ActivityGraphSummaryCard from "./ActivityGraphSummaryCard"
import SleepSummaryProvider from "./provider/SleepSummaryProvider"
import StepsSummaryProvider from "./provider/StepsSummaryProvider"
import useSleepSummaryStore from "./store/SleepSummaryStore"
import useStepSummaryStore from "./store/StepSummaryStore"

const ActivitiesSummarySection: React.FC = () => {
  const { currentStepsResponse } = useStepSummaryStore()
  const stepsUnit = currentStepsResponse?.unit || ""
  const stepsValue = currentStepsResponse?.average_steps || "--"
  const stepsTrend = currentStepsResponse?.trend || "none"
  const stepsChartData = currentStepsResponse?.chart_data || []

  const { currentSleepResponse } = useSleepSummaryStore()
  const sleepUnit = currentSleepResponse?.unit || ""
  const sleepValue = currentSleepResponse?.average_steps || "--"
  const sleepTrend = currentSleepResponse?.trend || "none"
  const sleepChartData = currentSleepResponse?.chart_data || []

  return (
    <SectionContainer title="Activities" description="All stats represent average of available data over last 30 days">
      <div className="flex gap-3">
        <SleepSummaryProvider>
          <ActivityGraphSummaryCard
            label="Daily Sleep"
            value={sleepValue}
            unit={sleepUnit}
            trend={sleepTrend}
            chartData={sleepChartData}
            color="#5E89FF"
          />
        </SleepSummaryProvider>{" "}
        <StepsSummaryProvider>
          <ActivityGraphSummaryCard
            label="Daily Steps"
            value={stepsValue}
            unit={stepsUnit}
            trend={stepsTrend}
            chartData={stepsChartData}
            color="#01B85E"
          />
        </StepsSummaryProvider>
        <ActivityGraphSummaryCard label={"Home Time"} value={"-"} unit="-" trend="none" />
      </div>
    </SectionContainer>
  )
}

export default ActivitiesSummarySection
