import { ColumnDef } from "@tanstack/react-table"

import { Checkbox } from "@/shadcn-components/ui/checkbox.tsx"

export const ManageCliniciansListColumns: ColumnDef<AdminClinicianItem>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        arias-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    header: "First Name",
    accessorKey: "profile.first_name",
  },
  {
    header: "Last Name",
    accessorKey: "profile.last_name",
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Roles",
    accessorKey: "roles",
    cell: ({ row }) => {
      const user = row.original
      return (
        <div>
          {user.roles.map((role) => (
            <span key={role} className="capitalize text-xs bg-gray-200 text-gray-600 rounded-full px-2 py-1 mr-1">
              {role}
            </span>
          ))}
        </div>
      )
    },
  },
]
