import UsersTable from "@components/Widgets/UsersTable/UsersTable.tsx"

import ManageUsersProvider from "@features/Admin/Users/providers/ManageUsersProvider.tsx"

const ManageUsers = () => {
  return (
    <ManageUsersProvider>
      <UsersTable />
    </ManageUsersProvider>
  )
}

export default ManageUsers
