import { useState } from "react"
import { useLocation } from "react-router-dom"

import SimpleListItem from "@/components/Lists/SimpleList/SimpleListItem"

import BaseButton from "@components/Buttons/BaseButton.tsx"

import ClientDetails from "@features/Home/components/ClientDetails.tsx"
import useProviderDashboardStore from "@features/Home/stores/ProviderDashboardStore.ts"
import AddAssignmentContainer from "@features/Surveys/AddAssignmentContainer.tsx"
import useSurveyStore from "@features/Surveys/stores/surveyStore.ts"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

interface SelectedAssignment {
  template_feed_item_id: string
  name: string
}

// todo 2024.09.19: refactor required
const AddAssignment = ({ afterClick }: { afterClick: () => void }) => {
  const [selectedAssignment, setSelectedAssignment] = useState<SelectedAssignment>()
  const location = useLocation()
  const state = location.state as { clientId: string }
  const clientId = state?.clientId
  const clients = useProviderDashboardStore((state) => state.clients)
  const client = clients.find((client) => client.user_id === clientId)
  const assignments = useSurveyStore((state) => state.surveySets) as unknown as SelectedAssignment[]

  if (!client || !clientId) return <div>Client not found</div>

  const postAssignment = async () => {
    if (!selectedAssignment) return
    const payload = {
      template_feed_item_id: selectedAssignment.template_feed_item_id,
    }
    try {
      const result = await api.post(`/template_feed_items/assign/${clientId}`, payload)
      if (result.status === 200) {
        success("Assignment added successfully", true)
        afterClick()
      }
    } catch (err) {
      error("Error adding assignment:", true)
    }
  }

  const handleSelectAssignment = (assignment: SelectedAssignment) => {
    setSelectedAssignment(assignment)
  }

  return (
    <AddAssignmentContainer>
      <div className={"rounded-xl px-2"}>
        <ClientDetails client={client} helperText={"You're manually adding an assignment for"} />
        <div className={"flex flex-col mt-4"}>
          {assignments.length === 0 && <p>No survey sets available</p>}
          <div className={"border"}>
            {assignments.map((assignment) => (
              <SimpleListItem
                key={assignment.name}
                label={assignment.name}
                selected={selectedAssignment === assignment}
                onClick={() => handleSelectAssignment(assignment)}
              />
            ))}
          </div>
        </div>
        <div className={"w-full flex flex-row justify-end"}>
          <BaseButton
            className={"shadow !mx-0"}
            textColor={"white"}
            color={"black"}
            disabled={!selectedAssignment}
            onClick={postAssignment}
          >
            Add Assignment
          </BaseButton>
        </div>
      </div>
    </AddAssignmentContainer>
  )
}
export default AddAssignment
