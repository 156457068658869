import ScoreBar from "../atom/ScoreBar"
import TrendIcon from "../atom/TrendIcon"

interface SurveyItemRowProps {
  title: string
  score: number
  trend: "up" | "down" | "none"
  date: string
  min_score: number
  max_score: number
  medium: number
  high: number
}
const SurveyItemRow: React.FC<SurveyItemRowProps> = ({
  title,
  score,
  trend,
  date,
  min_score,
  max_score,
  medium,
  high,
}) => {
  const formatDate = (date: string | undefined) => {
    if (!date) return ""
    const dateArr = date.split("-")
    return `${dateArr[1]}/${dateArr[2]}/${dateArr[0].slice(2)}`
  }

  return (
    <div className="flex flex-row justify-between items-center w-full not-last:border-b not-last:pb-4 pt-4 border-neutral-100 py-2">
      <div className="min-w-[50px]">{title}</div>
      <ScoreBar score={score} min_score={min_score} max_score={max_score} medium={medium} high={high} />
      <div className="min-w-[50px]">
        <TrendIcon trend={trend} additionalClasses="mr-1" reverseColor={true} />
        <span>{score}</span>
      </div>
      <div className="min-w-[90px]">{formatDate(date)}</div>
    </div>
  )
}

export default SurveyItemRow
