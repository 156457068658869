import { useAuth } from "@clerk/clerk-react"
import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import AcceptInvitationError from "@/core/Invitations/components/AcceptInvitationError.tsx"
import SignupError from "@/errors/SignupError.ts"

import api from "@utilities/api.ts"

interface SignupStatus {
  status: "success" | "error"
  redirect_url?: string
}

const PostSignupProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const { getToken } = useAuth()
  const { data, isError, error } = useQuery<SignupStatus>({
    queryKey: ["post-signup"],
    retry: (count, error) => {
      if (count > 10) {
        return false
      }

      if (error instanceof SignupError) {
        return true
      }

      return false
    },
    retryDelay: 1000,
    queryFn: async () => {
      // refresh the JWT, skipping the cache
      const token = await getToken({ skipCache: true })

      if (!token) {
        throw new SignupError("No token yet.", "not_ready")
      }

      // then make the request (overwrite the default headers)
      const response = await api.get<SignupStatus & StandardBackendError>("/users/self/ready", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status === 200 && response.data) {
        return { redirect_url: response.data.redirect_url, status: "success" }
      }

      if (response.status === 404) {
        throw new SignupError("Unable to check signup status. Please try refreshing the page.", "not_ready")
      }

      throw new Error(
        response.data?.message ??
          "Unknown account creation error. Please try refreshing the page or contacting support.",
      )
    },
  })

  useEffect(() => {
    if (data?.status === "success" && data.redirect_url) {
      navigate(data.redirect_url)
    }
  }, [navigate, data])

  useEffect(() => {
    // wipe out all the pre-signup cached data
    const keysToRemove = [
      "selected-experience",
      "selected-organization",
      "consents-complete",
      "consent-forms-completed",
      "terms-of-service-complete",
      "passive-sensing-consent",
      "privacy-policy-complete",
      "clinicianName",
      "clinicianStateOfPractice",
      "clinicianPhone",
    ]

    keysToRemove.forEach((key) => {
      localStorage.removeItem(key)
    })
  }, [])

  if (isError) return <AcceptInvitationError message={error.message} />

  return children
}

export default PostSignupProvider
