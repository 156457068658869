import DiskLabelImage from "@assets/images/color-disk-label.svg"
import DiskSideLabelImage from "@assets/images/color-disk-side-label.svg"
import DiskImage from "@assets/images/color-disk.png"
import { faRedo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Button from "@/components/Buttons/Button"

interface MoodDiscProps {
  imageRef: React.RefObject<HTMLImageElement>
  isHovering: boolean
  tooltipLocked: boolean
  selectedMood: string
  selectedIntensity: number
  handleReset: () => void
  handleNextClick: () => void
}

const MoodDisc: React.FC<MoodDiscProps> = ({
  imageRef,
  isHovering,
  tooltipLocked,
  selectedMood,
  selectedIntensity,
  handleReset,
  handleNextClick,
}) => {
  const intensityToDisplay = Math.floor(selectedIntensity * 10)
  return (
    <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center">
      <img ref={imageRef} src={DiskImage} className="object-contain h-full w-full" alt="Mood Color Disk" />
      <img
        src={DiskLabelImage}
        className={`absolute top-0 left-0 h-full w-full object-contain transition-opacity duration-300 ease-in-out ${isHovering ? "opacity-100" : "opacity-0"}`}
        alt="Mood Color Disk Label"
      />
      <img
        src={DiskSideLabelImage}
        className="absolute top-0 left-0 h-full w-full object-contain"
        alt="Mood Color Disk Side Label"
      />
      {/* After select */}
      <div
        className={`absolute top-0 left-0 h-full w-full flex transition-opacity duration-500 ease-in-out ${tooltipLocked ? "opacity-100" : "opacity-0"}`}
      >
        <div className="relative h-full w-full flex justify-center items-center">
          <div
            className={`absolute h-2/3 aspect-square rounded-full blur-2xl bg-${selectedMood.toLowerCase()}-200`}
          ></div>
          <div className={`absolute w-1/4 h-1/4 rounded-full blur-2xl bg-white`}></div>

          <div className="absolute font-semibold text-2xl pointer-events-none">{selectedMood}</div>
          <div className="absolute font-semibold text-lg pt-16 pointer-events-none">{intensityToDisplay}</div>
          <Button
            onClick={handleReset}
            size="small"
            className={`absolute bottom-14 right-2 ${tooltipLocked ? "pointer-events-auto" : "pointer-events-none"}`}
            disabled={!tooltipLocked}
          >
            <FontAwesomeIcon className={"text-sm font-normal text-white"} icon={faRedo} />
          </Button>
          <Button
            onClick={handleNextClick}
            className={`absolute bottom-0 right-2 ${tooltipLocked ? "pointer-events-auto" : "pointer-events-none"}`}
            disabled={!tooltipLocked}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MoodDisc
