import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import useMoodStore from "@features/Feed/stores/MoodStore.ts"

import api from "@utilities/api.ts"
import { useInvalidateQuery } from "@utilities/invalidateQueries.ts"

const MoodProvider = ({ children }: { children: ReactNode }) => {
  const invalidateMoodData = useInvalidateQuery(["calendarData"], 3000)
  const { date } = useParams()
  const currentActiveDate = date || new Date().toISOString().split("T")[0]
  const setCurrentMood = useMoodStore((state) => state.setCurrentMood)

  const {
    data: MoodAPIResponse,
    isSuccess: isSuccessMoodItem,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["moodData", currentActiveDate],
    queryFn: async () => {
      const endpoint = "/mood/self/" + currentActiveDate
      const response = await api.get<MoodItemAPIResponse>(endpoint)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching Feed data failed")
    },
  })

  useEffect(() => {
    if (isSuccessMoodItem) {
      // TODO(05/14/24): This is a temporary fix till the API is updated
      setCurrentMood(MoodAPIResponse.data)
    }
  }, [MoodAPIResponse, isSuccessMoodItem, setCurrentMood, currentActiveDate])

  useEffect(() => {
    void invalidateMoodData()
  }, [currentActiveDate, invalidateMoodData])
  if (isLoading)
    return (
      <div className="mt-6">
        <LoadingSpinner size="2x" />
      </div>
    )
  if (isError) return <div className="mt-6">Failed to load mood</div>
  return children
}

export default MoodProvider
