import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import ActivityGraphSummaryCard from "../summary/ActivityGraphSummaryCard"
import SleepSummaryProvider from "../summary/provider/SleepSummaryProvider"
import StepsSummaryProvider from "../summary/provider/StepsSummaryProvider"
import useSleepSummaryStore from "../summary/store/SleepSummaryStore"
import useStepSummaryStore from "../summary/store/StepSummaryStore"
import DailySleepAndStepsCard from "./DailySleepAndStepsCard"
import SleepAndStepsCard from "./SleepAndStepsCard"
import ActivityStackGraphProvider from "./provider/ActivityStackGraphProvider"

const SleepAndStepsSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"
  const { currentStepsResponse } = useStepSummaryStore()
  const stepsUnit = currentStepsResponse?.unit || ""
  const stepsValue = currentStepsResponse?.average_steps || "--"
  const stepsTrend = currentStepsResponse?.trend || "none"
  const stepsChartData = currentStepsResponse?.chart_data || []

  const { currentSleepResponse } = useSleepSummaryStore()
  const sleepUnit = currentSleepResponse?.unit || ""
  const sleepValue = currentSleepResponse?.average_steps || "--"
  const sleepTrend = currentSleepResponse?.trend || "none"
  const sleepChartData = currentSleepResponse?.chart_data || []

  return (
    <SectionContainer title="Sleep & Steps" description="All stats latest available score within the last 90 days">
      <div className="flex gap-3 flex-col">
        <div className="flex gap-3">
          <SleepSummaryProvider>
            <ActivityGraphSummaryCard
              label="Daily Sleep"
              value={sleepValue}
              unit={sleepUnit}
              trend={sleepTrend}
              chartData={sleepChartData}
              color="#5E89FF"
            />
          </SleepSummaryProvider>{" "}
          <StepsSummaryProvider>
            <ActivityGraphSummaryCard
              label="Daily Steps"
              value={stepsValue}
              unit={stepsUnit}
              trend={stepsTrend}
              chartData={stepsChartData}
              color="#01B85E"
            />
          </StepsSummaryProvider>
        </div>
        <Card additionalClasses={cardStyle}>
          <DailySleepAndStepsCard />
          <ActivityStackGraphProvider>
            <SleepAndStepsCard />
          </ActivityStackGraphProvider>
        </Card>
      </div>
    </SectionContainer>
  )
}

export default SleepAndStepsSection
