// todo 2024.10.23: genericize and move me to a different location
import { CalendarIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"
import { format } from "date-fns"
import { get } from "lodash"
import { SelectSingleEventHandler } from "react-day-picker"

import { Button } from "@/shadcn-components/ui/button.tsx"
import { Calendar } from "@/shadcn-components/ui/calendar.tsx"
import { Checkbox } from "@/shadcn-components/ui/checkbox.tsx"
import { Input } from "@/shadcn-components/ui/input.tsx"
import { Popover, PopoverContent, PopoverTrigger } from "@/shadcn-components/ui/popover.tsx"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/shadcn-components/ui/select.tsx"

import useAddAssignmentStore from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsStore.ts"

import { cn } from "@utilities/shadcn-utils.ts"

interface Props<TData> {
  row: Row<TData>
  id: string
  type: "input" | "select" | "checkbox_group" | "date"
  options?: { value: string | null; label: string }[]
  className?: string | undefined
  placeholder?: string
  inputType?: string
}

type PermissableValue = string | number | boolean | Date | string[] | undefined

const EditableCell = ({
  row,
  id,
  type,
  options,
  className,
  placeholder,
  inputType = "text",
}: Props<AdminAssignmentItem>) => {
  const updateAssignment = useAddAssignmentStore((state) => state.updateAssignment)
  const value = get(row.original, id) as PermissableValue

  const handleChange = (newValue: string | string[] | null) => {
    updateAssignment(row.original.template_feed_item_id, id, newValue)
  }

  if (type === "input") {
    return (
      <Input
        className={className}
        key={row.original.template_feed_item_id}
        value={value as string | number | readonly string[] | undefined}
        onChange={(e) => handleChange(e.target.value)}
        type={inputType ?? "text"}
        placeholder={placeholder}
      />
    )
  }

  if (type === "select") {
    return (
      <Select
        key={row.original.template_feed_item_id}
        onValueChange={handleChange as unknown as (value: string) => void}
        defaultValue={value as string}
      >
        <SelectTrigger>
          <SelectValue placeholder={(value as string) || "None"} />
        </SelectTrigger>
        <SelectContent>
          {options?.map((option) => (
            <SelectItem key={option.value} value={option.value!}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }

  if (type === "checkbox_group") {
    return (
      <div className={className}>
        {options?.map((option) => (
          <div key={option.value} className={"flex flex-row gap-x-2"}>
            <Checkbox
              checked={((value as string[] | null) || ([] as string[])).includes(option.value!)}
              onCheckedChange={(checked) => {
                const currentValues = (value || []) as string[]

                if (checked) {
                  handleChange([...currentValues, option.value!])
                } else {
                  handleChange(currentValues.filter((v) => v !== option.value))
                }
              }}
            ></Checkbox>
            <label>{option.label}</label>
          </div>
        ))}
      </div>
    )
  }

  if (type === "date") {
    const createLocalDate = (dateString: string) => {
      const [year, month, day] = dateString.split("-")
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day)) // months are zero-indexed in Date constructor
    }
    const date = value ? createLocalDate(value as string) : null

    return (
      <Popover key={row.original.template_feed_item_id}>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn("w-[140px] pl-3 text-left font-normal", !value && "text-muted-foreground")}
          >
            {date ? format(date, "P") : <span>Pick a date</span>}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={date as Date | undefined}
            defaultMonth={date!}
            onSelect={(newValue) =>
              handleChange(format(newValue!, "yyyy-MM-dd")) as unknown as SelectSingleEventHandler
            }
            initialFocus
          />
          <Button onClick={() => handleChange(null)} variant="ghost" className="w-full text-left">
            Clear
          </Button>
        </PopoverContent>
      </Popover>
    )
  }
}

export default EditableCell
