import { ROLES } from "@/constants/roles.const.ts"
import useAuthStore from "@/core/Authentication/stores/AuthStore"
import { PROVIDER_ROLES } from "@/data/roles.const.ts"

import AdminHome from "@features/Home/Homes/AdminHome.tsx"
import ClientHome from "@features/Home/Homes/ClientHome.tsx"
import ProviderHome from "@features/Home/Homes/ProviderHome.tsx"

const Home = () => {
  const user = useAuthStore((state) => state.user)
  if (user.roles.some((role) => role === ROLES.CLIENT)) {
    return <ClientHome />
  }

  if (PROVIDER_ROLES.some((role) => user.roles.includes(role))) {
    return <ProviderHome />
  }

  if (user.roles.some((role) => role === ROLES.ADMIN)) {
    return <AdminHome />
  }

  // todo: create a new error component for this
  return <div>Error: this user role does not have a home. Please contact support for help.</div>
}

export default Home
