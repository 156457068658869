import { ReactNode } from "react"

import CardEmptyState from "@/components/Cards/Card/CardStates/CardEmptyState.tsx"
import CardErrorState from "@/components/Cards/Card/CardStates/CardErrorState.tsx"
import CardNotFoundState from "@/components/Cards/Card/CardStates/CardNotFoundState.tsx"

import LoadingSpinner from "../Loading/LoadingSpinner.tsx"

const Card = ({
  width = 12,
  minHeight,
  backgroundColor = "white",
  textColor = "",
  additionalClasses = "",
  transparent = false,
  hasBorder = false,
  loadingStatus = "loaded",
  useGradient = false,
  placeholderComponents = {},
  children,
  padding = "p-8",
}: CardTypeProps & { loadingStatus?: CRUDLoaderStatuses; children: ReactNode }) => {
  const { EmptyStateComponent, NotFoundStateComponent, ErrorStateComponent } = placeholderComponents
  const calculateGradient = () => {
    const exclusions = ["white", "black", "transparent"]

    if (!useGradient) return `bg-${backgroundColor}`
    if (exclusions.includes(backgroundColor)) return ""

    const [color, value] = backgroundColor.split("-")
    return `bg-gradient-to-b from-${color}-${value} to-${color}-${parseInt(value) + 100}`
  }

  let classes = `lg:col-span-${width} ${hasBorder ? "border" : ""} col-span-12 ${additionalClasses} ${minHeight ? "min-h-[" + minHeight + "]" : ""} `
  classes = !transparent ? `${padding} shadow-lg rounded-xl ${classes}` : classes
  classes = classes + " " + calculateGradient()
  classes = classes + ` text-${textColor}`

  return (
    <div className={classes}>
      {loadingStatus === "loading" && <LoadingSpinner fullScreen={false} size={"2x"} />}
      {loadingStatus === "loaded" && children}
      {loadingStatus === "empty" && <CardEmptyState EmptyStateComponent={EmptyStateComponent} />}
      {loadingStatus === "not_found" && <CardNotFoundState NotFoundStateComponent={NotFoundStateComponent} />}
      {loadingStatus === "error" && <CardErrorState ErrorStateComponent={ErrorStateComponent} />}
    </div>
  )
}

export default Card
