"use client"

import { ArrowDownIcon, ArrowUpIcon, CaretSortIcon, EyeNoneIcon, MixerHorizontalIcon } from "@radix-ui/react-icons"
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  Table as TableType,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"

import { Button } from "@/shadcn-components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shadcn-components/ui/dropdown-menu.tsx"
import { Input } from "@/shadcn-components/ui/input.tsx"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/shadcn-components/ui/table"
import { ExtendedColumnDef } from "@/types/extensions/ExtendedColumnDef"
import { cn } from "@/utilities/shadcn-utils.ts"

import { DataTablePagination } from "@components/Tables/DataTable/DataTablePagination.tsx"

interface DataTableProps<TData, TValue> {
  title?: string
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  ActionButtonsSection?: ReactNode
  initialRowSelection?: Record<string, boolean>
  setSelectedRows?: Dispatch<SetStateAction<TData[]>>
}

interface DataTableViewOptionsProps<TData> {
  table: TableType<TData>
}

export function DataTable<TData, TValue>({
  title,
  columns,
  data,
  ActionButtonsSection = <div></div>,
  setSelectedRows,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({})

  /* Add support for "show" field on columns */
  useEffect(() => {
    const columnIds = columns.map((col) => col.id)
    const initialColumnVisibility = columns
      .filter((col) => (col as ExtendedColumnDef<TData>).showByDefault === false)
      .map((col) => col.id)
      .reduce((visibilityState, columnId) => {
        if (columnId !== undefined) {
          // @ts-expect-error - TS doesn't know that columnId is a key of visibilityState
          visibilityState[columnId] = !columnIds.includes(columnId)
        }
        return visibilityState
      }, {})

    setColumnVisibility(initialColumnVisibility)
  }, [columns])

  const state = {
    sorting,
    globalFilter,
    rowSelection,
    columnVisibility,
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state,
  })

  useEffect(() => {
    if (setSelectedRows) {
      const originals = table.getSelectedRowModel().rows.map((row) => row.original)
      setSelectedRows(originals)
    }
  }, [table, rowSelection, setSelectedRows])

  return (
    <div>
      <div className={"flex justify-between items-center lg:mb-4"}>
        <h2>{title || ""}</h2>
        <div className={"flex items-center gap-4"}>
          <div className="flex items-center py-4">
            <Input
              placeholder="Search"
              onChange={(event) => table.setGlobalFilter(String(event.target.value))}
              className="max-w-sm"
            />
          </div>
          <DataTableViewOptions table={table} />
          {ActionButtonsSection}
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  title: string
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>
  }

  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="-ml-3 h-8 data-[state=open]:bg-accent">
            <span>{title}</span>
            {column.getIsSorted() === "desc" ? (
              <ArrowDownIcon className="ml-2 h-4 w-4" />
            ) : column.getIsSorted() === "asc" ? (
              <ArrowUpIcon className="ml-2 h-4 w-4" />
            ) : (
              <CaretSortIcon className="ml-2 h-4 w-4" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Ascending (A-Z)
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Descending (Z-A)
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
            <EyeNoneIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Hide
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export function DataTableViewOptions<TData>({ table }: DataTableViewOptionsProps<TData>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className="ml-auto hidden h-8 lg:flex">
          <MixerHorizontalIcon className="mr-2 h-4 w-4" />
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[150px]">
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {table
          .getAllColumns()
          .filter((column) => typeof column.accessorFn !== "undefined" && column.getCanHide())
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            )
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
