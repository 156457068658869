import HabitsItemRow from "./HabitsItemRow"
import HabitsItemRowHeader from "./HabitsItemRowHeader"
import useHabitsSummaryStore from "./store/HabitsSummaryStore"

const getDayOfWeekUTC = (dateString: string) => {
  const date = new Date(dateString)
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  return daysOfWeek[date.getUTCDay()]
}

const HabitsSummaryContent: React.FC = () => {
  const { currentHabitsResponse } = useHabitsSummaryStore()

  if (!currentHabitsResponse) return null

  return (
    <>
      <HabitsItemRowHeader />
      {currentHabitsResponse.habit_data.map((habitEntry) => {
        const dateKey = Object.keys(habitEntry.date)[0]
        const habitDetails = habitEntry.date[dateKey]

        const habitScaleMap = {
          meds: habitDetails.daily_habits_medications?.answer_value || null,
          alcohol: habitDetails.daily_habits_alcohol?.answer_value || null,
          cannabis: habitDetails.daily_habits_marijuana?.answer_value || null,
          caffeine: habitDetails.daily_habits_caffeine?.answer_value || null,
          screen: habitDetails.daily_habits_screentime?.answer_value || null,
          junkFood: habitDetails.daily_habits_processed_foods?.answer_value || null,
        }

        const dayOfWeek = getDayOfWeekUTC(dateKey)
        return <HabitsItemRow key={dateKey} dayOfWeek={dayOfWeek} habitScaleMap={habitScaleMap} date={dateKey} />
      })}
    </>
  )
}

export default HabitsSummaryContent
