interface Config {
  api: {
    baseURL: string
  }
  clerk: {
    instance_id: string
    app_id: string
  }
  geolocation_api_key: string
}

export let config: Config = {
  api: {
    baseURL: "",
  },
  clerk: {
    instance_id: "",
    app_id: "",
  },
  geolocation_api_key: "",
}

const stagingMode = window.location.hostname == "staging.personily.com"
const productionMode = window.location.hostname == "app.personily.com"
let clerkConfig = {
  instance_id: import.meta.env.VITE_CLERK_INSTANCE_ID as string,
  app_id: import.meta.env.VITE_CLERK_APP_ID as string,
}

if (stagingMode) {
  clerkConfig = {
    instance_id: "instance_id",
    app_id: "app_id",
  }
}

if (productionMode) {
  clerkConfig = {
    instance_id: "ins_2ZofLkLUBCO6WcT410Xk0yu9KU7",
    app_id: "app_2ZJ5rA4Y6VEVKzHLLB9Q1dimbKW",
  }
}

if (import.meta.env.MODE === "development") {
  config = {
    api: {
      baseURL: import.meta.env.VITE_API_SERVER as string,
    },
    clerk: clerkConfig,
    geolocation_api_key:
      (import.meta.env.VITE_GEOLOCATION_API_PUBLIC_KEY as string) ?? "AIzaSyDLOzowzby-FA6vsr6MFsCTiEPkSSJ2RbU",
  }
} else {
  config = {
    api: {
      baseURL: import.meta.env.VITE_API_SERVER as string,
    },
    clerk: clerkConfig,
    geolocation_api_key: "AIzaSyDveoQf_f2tiLYoMuFNdCiKP5r3RqQzIdg",
  }
}
