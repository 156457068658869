import SurveySection from "./SurveySection"

const SurveyPage: React.FC = () => {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="w-full">
          <h3>Survey</h3>
        </div>
  
        <SurveySection />
      </div>
    )
  }
  
  export default SurveyPage
  