import { faExpand } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode } from "react"

interface SectionContainerProps {
  title?: string
  description?: string
  marginSize?: "sm" | "md" | "lg"
  children: ReactNode
}
const SectionContainer = ({ title, children, description, marginSize = "sm" }: SectionContainerProps) => {
  const showSectionHeader = title || description
  const rowMargin = marginSize === "sm" ? "mb-3" : marginSize === "md" ? "mb-6" : "mb-9"
  const containerStyle = `border w-full bg-neutral-50 ${rowMargin} rounded-xl p-4 max-w-5xl`
  return (
    <div className={containerStyle}>
      <div className="flex flex-row justify-between">
        {showSectionHeader && (
          <div className="flex flex-row w-full gap-2 justify-start mb-2 ml-1">
            <div className="text-neutral-500">{title}</div>
            <div className="text-neutral-300 text-sm pt-1">{description}</div>
          </div>
        )}
        {showSectionHeader && (
          <div className="text-neutral-300">
            <FontAwesomeIcon icon={faExpand} size="sm" />
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export default SectionContainer
