import { useQueryClient } from "@tanstack/react-query"
import { Suspense, lazy, useCallback, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

import LoadingSpinner from "@components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"
import { error, log, success, warn } from "@utilities/logger.ts"

import CurrentStateCard from "./CurrentStateCard"
import PRLGameRenderer from "./Game/PRLGameRenderer"
import useFeedAssignmentDetailStore from "./stores/FeedAssignmentDetailStore"
import useSurveyProgressStore from "./stores/SurveyProgressStore"

const SurveyRenderer = lazy(() => import("./Survey/SurveyRenderer"))

/**
 * This component is the main component for the task module
 * It renders the CurrentStateCard and the task components
 */

// Main task module component
const FeedAssignmentRenderer = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const step = parseInt(searchParams.get("step") ?? "0")
  const currentAssignmentDetailInStore = useFeedAssignmentDetailStore((state) => state.assignmentDetail)
  const feedSurveyLoading = useFeedAssignmentDetailStore((state) => state.isAssignmentDetailLoading)
  const surveyProgressLoading = useSurveyProgressStore((state) => state.isProgressLoading)
  const queryClient = useQueryClient()

  const [tasks, setTasks] = useState<Task[]>([])

  const params = useParams()
  const feed_item_id: string = params.feedItemId ? params.feedItemId : ""

  const getAssignmentTasks = useCallback(
    (feed_item_id: string | undefined) => {
      if (!feed_item_id) {
        log("Assignment ID is undefined.")
        return
      }

      // Retrieve assignment details by ID
      if (!currentAssignmentDetailInStore) {
        log(`No assignments found or assignments are still loading.`)
        return
      }
      if (!feedSurveyLoading && !currentAssignmentDetailInStore) {
        warn(`No tasks found for assignment ID: ${feed_item_id}`)
        window.location.href = "/"
        return
      }

      // Initialize an array to hold formatted tasks
      const new_tasks: Task[] = []

      // If surveys exist in assignment details, format and add them as tasks
      if (currentAssignmentDetailInStore?.surveys) {
        const survey_task = {
          title: currentAssignmentDetailInStore?.surveys.title || "Unnamed Survey",
          type: "questionnaire",
          content: currentAssignmentDetailInStore?.surveys,
        }
        // @ts-expect-error Need to fix typing for survey_task todo: fix this
        new_tasks.push(survey_task)
      }

      // Iterate through tasks in assignment details and format each as a Task
      currentAssignmentDetailInStore?.tasks?.forEach((task) => {
        const formatted_task = {
          title: task.title || "Unnamed Task",
          type: task.dtype || "none", // Ensure dtype is defined or handle it accordingly
          content: task.attributes,
        }
        if (!formatted_task.content) {
          warn(`Task content is undefined for task: ${formatted_task.title}`)
          return
        }
        formatted_task.content.feed_item_id = feed_item_id
        // @ts-expect-error Need to fix typing for formatted_task todo: fix this
        new_tasks.push(formatted_task)
      })

      // Update the state with the new set of tasks
      setTasks(new_tasks)
    },
    [feedSurveyLoading, currentAssignmentDetailInStore],
  )

  const onAssignmentFinish = useCallback(async () => {
    const completed_tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    try {
      const result = await api.put(`/feed/self/complete/${feed_item_id}`, {
        completed_tz,
      })
      if (result.status === 200) {
        success("Feed item completed successfully", true)
      }
    } catch (err) {
      error("Error completing feed survey item:", true)
    }

    void queryClient.invalidateQueries({ queryKey: ["assignmentDetailData"] })
    setTasks([])
  }, [feed_item_id, queryClient])

  useEffect(() => {
    getAssignmentTasks(feed_item_id)
  }, [feed_item_id, getAssignmentTasks])

  useEffect(() => {
    if (tasks.length !== 0 && step >= tasks.length) {
      void onAssignmentFinish()
    }
  }, [onAssignmentFinish, step, tasks.length])

  useEffect(() => {
    if (!searchParams.get("step")) {
      const newSearchParams = new URLSearchParams(searchParams.toString())
      newSearchParams.set("step", "0")
      setSearchParams(newSearchParams)
    }
  }, [searchParams, setSearchParams])

  // functions to control the task flow
  const finishSubTask = () => {
    goToNextTask()
  }

  const goToNextTask = () => {
    const newStep = step + 1
    const newSearchParams = new URLSearchParams(searchParams.toString())
    newSearchParams.set("step", newStep.toString())
    newSearchParams.delete("page")
    setSearchParams(newSearchParams)
  }

  const renderTaskComponent = (task: Task) => {
    switch (task.type) {
      case "questionnaire":
        return (
          <SurveyRenderer feed_item_id={feed_item_id} survey_obj={task.content as SurveyObj} onFinish={finishSubTask} />
        )
      case "prl":
        return (
          <PRLGameRenderer feed_item_id={feed_item_id} game_obj={task.content as GameObj} onFinish={finishSubTask} />
        )
      default:
        return <div>Unknown task type</div>
    }
  }

  // Extract titles from tasks for the RoadmapCard
  const titles = tasks.map((task) => task.title)

  if (step >= tasks.length) {
    if (tasks.length === 0 && feedSurveyLoading) {
      return <LoadingSpinner />
    }
    return <CurrentStateCard titles={titles} currentStep={step} />
  }

  if (feedSurveyLoading || surveyProgressLoading) return <LoadingSpinner />

  return (
    <div>
      <CurrentStateCard titles={titles} currentStep={step} />
      <Suspense fallback={<LoadingSpinner />}>{renderTaskComponent(tasks[step])}</Suspense>
    </div>
  )
}

export default FeedAssignmentRenderer
