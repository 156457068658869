import { Row } from "@tanstack/react-table"
import { useState } from "react"

import { Dialog, DialogContent } from "@/shadcn-components/ui/dialog.tsx"

import AddAssignmentsProvider from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/AddAssignmentsProvider.tsx"
import ConfirmAssignments from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/Steps/ConfirmAssignments.tsx"
import ModifyAssignments from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/Steps/ModifyAssignments.tsx"
import SelectAssignments from "@components/Widgets/UsersTable/DropdownMenuItems/AddAssignments/Steps/SelectAssignments.tsx"

interface Props {
  row: Row<ListUser>
  triggerProps: TriggerProps
}

const AddAssignmentsDialog = ({ row, triggerProps }: Props) => {
  const [page, setPage] = useState(0)

  return (
    <Dialog {...triggerProps}>
      <DialogContent className={"min-w-[90%]"}>
        <AddAssignmentsProvider targetUserId={row.original.user_id}>
          {page === 0 && <SelectAssignments setPage={setPage} />}
          {page === 1 && <ModifyAssignments setPage={setPage} />}
          {page === 2 && <ConfirmAssignments setPage={setPage} row={row} triggerProps={triggerProps} />}
        </AddAssignmentsProvider>
      </DialogContent>
    </Dialog>
  )
}

export default AddAssignmentsDialog
