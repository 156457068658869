import SurveyItemRow from "./SurveyItemRow.tsx"
import SurveyItemRowHeader from "./SurveyItemRowHeader.tsx"
import useSurveySummaryStore from "./store/SurveySummaryStore.ts"

const SurveysSummaryContent: React.FC = () => {
  const { currentSurveyResponse } = useSurveySummaryStore()

  if (!currentSurveyResponse) return null

  return (
    <>
      <SurveyItemRowHeader />
      {Object.entries(currentSurveyResponse.survey_scores).map(([title, scoreData]) => (
        <SurveyItemRow
          key={title}
          title={title.toUpperCase()}
          score={scoreData.score}
          trend={scoreData.trend}
          date={scoreData.date}
          min_score={scoreData.min_score}
          max_score={scoreData.max_score}
          medium={scoreData.medium}
          high={scoreData.high}
        />
      ))}
    </>
  )
}

export default SurveysSummaryContent
